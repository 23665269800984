/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Country, State, City } from "country-state-city";
import {
  EMAIL_REGEX,
  CHAR_REGEX,
  SPECIAL_CHAR,
  GET_LOWER_CASE,
  GET_UPPER_CASE,
  GET_NUMBER,
} from "../../utils/Regex";
import {
  useForm,
  Controller,
  SelectPicker,
  Modal,
  Button,
  swal,
} from "../../utils/Packages";
import { useHistory, useParams } from "react-router-dom";
import { submitOffer, userSignup } from "../../redux/actions/APIs";
import * as image from "../../resources/images";
import { isLoading, showModal, resetEmailResponse } from "../../redux/actions";

import { MSG } from "../../utils/Messages";

const SubmitPropertyOffer = () => {
  const params = useParams();
  const { loading, validateUser, response } = useSelector(
    (state) => state.collections
  );

  const [type, setType] = useState("password");

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState();

  const _onSubmit = (values) => {
    dispatch(isLoading(true));
    values.listing_id = params?.slug;
    dispatch(submitOffer(values));
  };

  useEffect(() => {
    if (response) {
      new swal({
        position: "top-center",
        icon: "success",
        title: "Success",
        timer: 2000,
        text: response?.message,
        type: "success",
      });
      dispatch(submitOffer());
      reset();
    }
  }, [response]);

  const basicForm = (
    <div className="flex flex-wrap align-items-start">
      <div className="input-group mb-2">
        <div className="row">
          <div className="col-md-4">
            <label>
              Name<span className="mandatoryField">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <div className="form-group">
              <input
                type="text"
                // placeholder="First Name"
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                {...register("name", {
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                  pattern: {
                    value: CHAR_REGEX,
                    message: MSG.ALPHAREQ,
                  },
                })}
              />

              <div className="invalid-feedback">{errors?.name?.message}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="input-group mb-2">
        <div className="row">
          <div className="col-md-4">
            <label>
              Email <span className="mandatoryField">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <div className="form-group">
              <input
                type="text"
                className={`form-control ${
                  errors.email || validateUser?.message ? "is-invalid" : ""
                }`}
                {...register("email", {
                  required: {
                    value: true,
                    message: MSG.EMAILREQ,
                  },
                  pattern: {
                    value: EMAIL_REGEX,
                    message: MSG.INVEMAILREQ,
                  },
                })}

                // onBlur={(e)=>handleEmail(e)}
              />
              <div className="invalid-feedback">{errors?.email?.message}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="input-group mb-2">
        <div className="row">
          <div className="col-md-4">
            <label>
              Phone No.<span className="mandatoryField">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <div className="form-group">
              <input
                type="text"
                className={`form-control ${
                  errors.phone || validateUser?.message ? "is-invalid" : ""
                }`}
                {...register("phone", {
                  required: {
                    value: true,
                    message: MSG.EMAILREQ,
                  },
                })}
              />
              <div className="invalid-feedback">{errors?.phone?.message}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="input-group mb-2">
        <div className="row">
          <div className="col-md-4">
            <label>
              Offer Amount<span className="mandatoryField">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <div className="form-group">
              <input
                type="text"
                // placeholder="Email/Username"
                className={`form-control ${
                  errors.offer_price || validateUser?.message
                    ? "is-invalid"
                    : ""
                }`}
                {...register("offer_price", {
                  required: {
                    value: true,
                    message: "Offer Price is required",
                  },
                })}
              />
              <div className="invalid-feedback">
                {errors?.offer_price?.message}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="input-group mb-2">
        <div className="row">
          <div className="col-md-4">
            <label>
              Message<span className="mandatoryField">*</span>
            </label>
          </div>
          <div className="col-md-8">
            <div className="form-group">
              <textarea
                // placeholder="Email/Username"
                className={`form-control ${
                  errors.message || validateUser?.message ? "is-invalid" : ""
                }`}
                {...register("message", {
                  required: {
                    value: true,
                    message: "Message is required",
                  },
                })}
              />
              <div className="invalid-feedback">{errors?.message?.message}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Modal
        show={true}
        className="SignupPopup AddChildPopup"
        onHide={() => dispatch(showModal())}
      >
        <Modal.Body className="p-0">
          <div className="SignupRegistration basicdetailsform in-up-form">
            <Modal.Header>
              <div class="modal-title flex h4">
                <i className="fa-solid fa-id-card mr-2 fontawsomeicon"></i>
                <h3>Submit Property Offer</h3>{" "}
              </div>
              <Button
                data-dismiss="modal"
                onClick={() => dispatch(showModal())}
              >
                <i className="fa-solid fa-xmark m-0"></i>
              </Button>
            </Modal.Header>
            <div id="signin">
              <div className="p-3">
                <form
                  name="usersignup"
                  className="content"
                  onSubmit={handleSubmit(_onSubmit)}
                >
                  {basicForm}

                  <div className="d-flex align-items-center justify-content-end">
                    {loading ? (
                      <button
                        className="black-btn btn-small"
                        key={Math.random()}
                      >
                        <span className="RounAnimation"></span> Please Wait...
                      </button>
                    ) : (
                      <button
                        key={Math.random()}
                        type="submit"
                        className="black-btn btn-small"
                      >
                        <i className="fa-solid fa-paper-plane"></i> Send Enquiry
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SubmitPropertyOffer;
