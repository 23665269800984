/** @format */

import {
  SIDEBAR,
  ISLOADING,
  STUSCORE,
  SELECTED_USER,
  RESPONSE,
  RESPONSE_FAILURE,
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  LOGGEDIN_USER,
  CHILD_LOGIN,
  PARENT_LOGIN,
  UPDATE_INIT_ASS_RESPONSE,
  GET_PROVIDER,
  TECHERASSESSSUBMIT,
  GET_ASSDATA,
  ENROLL_RESPONSE,
  GET_ASSESS_OF_LEARNERS,
  GET_ENROLLED_LEARNERS,
  ADD_PROVIDER_DETAIL,
  RESET_SONG,
  META_RESPONSE,
  ADD_LEARNER,
  SELECTED_DIMTAB,
  SELECTED_PLAN,
  BILLING_PLAN,
  GET_SOCIAL_ACTIVITY_DATA,
  GET_GRADE_LEVEL,
  GET_INTEREST_CATEGORIES,
  GET_ALPHABET_DATA,
  USER_SIGNUP,
  GET_INTEREST_CATEGORY_BY_ID,
  BREADCRUMB,
  HELPMODAL,
  SHOWFORM,
  GET_USER_NAME_RESPONSE,
  RESPONSE_OK,
  KNOWLADGE_CHECK,
  NEXT_SCENE_RESPONSE,
} from "./Types";

export const ResetSong = (type) => async (dispatch) => {
  dispatch({ type: RESET_SONG, payload: type });
};
export const selectTab = (data) => async (dispatch) => {
  dispatch({ type: SELECTED_DIMTAB, payload: data });
};

export const sceanIndex = (data) => async (dispatch) => {
  dispatch({ type: BREADCRUMB, payload: data });
};

export const breadcrumb = (data) => async (dispatch) => {
  dispatch({ type: BREADCRUMB, payload: data });
};

export const sidebarCollapse = (flag) => async (dispatch) => {
  dispatch({ type: SIDEBAR, payload: flag });
};

export const isLoading = (flag) => async (dispatch) => {
  dispatch({ type: ISLOADING, payload: flag });
};

export const getScore = (data) => async (dispatch) => {
  dispatch({ type: STUSCORE, payload: data });
};

export const resetGuestResponse = () => async (dispatch) => {
  dispatch({ type: RESPONSE, payload: "" });
  dispatch({ type: RESPONSE_FAILURE, payload: "" });
  dispatch({ type: RESPONSE_OK, payload: "" });
};

export const resetResponse = () => async (dispatch) => {
  dispatch({ type: GET_SOCIAL_ACTIVITY_DATA, payload: undefined });
  dispatch({ type: NEXT_SCENE_RESPONSE, payload: undefined });
  dispatch({ type: KNOWLADGE_CHECK, payload: undefined });
  dispatch({ type: SELECTED_DIMTAB, payload: undefined });
  dispatch({ type: BREADCRUMB, payload: undefined });
  dispatch({ type: RESPONSE, payload: "" });
  dispatch({ type: RESPONSE_FAILURE, payload: "" });
  dispatch({ type: USER_LOGIN, payload: "" });
  dispatch({ type: USER_LOGIN_FAILURE, payload: "" });
  dispatch({ type: UPDATE_INIT_ASS_RESPONSE, payload: "" });
  dispatch({ type: GET_ASSESS_OF_LEARNERS, payload: "" });
  dispatch({ type: GET_ASSDATA, payload: "" });
  dispatch({ type: GET_ENROLLED_LEARNERS, payload: "" });
  dispatch({ type: META_RESPONSE, payload: undefined });
  dispatch({ type: GET_PROVIDER, payload: "" });
  dispatch({ type: GET_ASSDATA, payload: "" });
  dispatch({ type: ENROLL_RESPONSE, payload: "" });
  dispatch({ type: ADD_PROVIDER_DETAIL, payload: "" });

  dispatch({ type: ADD_LEARNER, payload: "" });
  dispatch({ type: BILLING_PLAN, payload: "" });
  dispatch({ type: GET_SOCIAL_ACTIVITY_DATA, payload: "" });
  dispatch({ type: GET_GRADE_LEVEL, payload: "" });
  dispatch({ type: GET_INTEREST_CATEGORIES, payload: "" });
  dispatch({ type: GET_ALPHABET_DATA, payload: "" });
  dispatch({ type: USER_SIGNUP, payload: "" });
  dispatch({ type: GET_INTEREST_CATEGORY_BY_ID, payload: "" });
};

export const resetBreadCrumb = () => async (dispatch) => {
  dispatch({ type: GET_SOCIAL_ACTIVITY_DATA, payload: undefined });
  // dispatch({ type: BREADCRUMB, payload: undefined });
};
export const resetEnrollResponse = () => async (dispatch) => {
  dispatch({ type: ENROLL_RESPONSE, payload: "" });
};

export const resetError = () => async (dispatch) => {
  dispatch({ type: RESPONSE_FAILURE, payload: "" });
};
export const updateScore = (data, score, id) => async (dispatch) => {
  let masterItem = [];

  let item = [];

  if (data) {
    item = data.map((value, ky) => {
      masterItem[ky] = value;
      if (value.title === score.title) {
        masterItem[ky]["score"] = id;
      }
    });
  }

  dispatch({ type: STUSCORE, payload: masterItem });
};

export const selectedUser = (data) => async (dispatch) => {
  dispatch({ type: SELECTED_USER, payload: data });
};

export const responseFailure = (data) => async (dispatch) => {
  if (data.response?.data?.message) {
    dispatch({ type: RESPONSE_FAILURE, payload: data.response?.data?.message });
  } else {
    dispatch({ type: RESPONSE_FAILURE, payload: data?.message });
  }
};

export const getTeacherAssesSubmit = (data) => async (dispatch) => {
  dispatch({ type: TECHERASSESSSUBMIT, payload: data });
};

export const selectedPlan = (data) => async (dispatch) => {
  dispatch({ type: SELECTED_PLAN, payload: data });
};

export const billingPlan = (data) => async (dispatch) => {
  dispatch({ type: BILLING_PLAN, payload: data });
};

export const getHelpModal = (data) => async (dispatch) => {
  dispatch({ type: HELPMODAL, payload: data });
};

export const showModal = (obj) => async (dispatch) => {
  dispatch({ type: SHOWFORM, payload: obj });
};

export const resetEmailResponse = () => async (dispatch) => {
  dispatch({ type: GET_USER_NAME_RESPONSE, payload: "" });
};
