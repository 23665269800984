/** @format */

import {
  USER_LOGIN,
  GET_ALL_USERS_DETAIL_SUCCESS,
  BASIC_DETAIL_ICON,
  USER_LOGIN_FAILURE,
  LOGOUT,
  RESPONSE,
  UPLOAD_RESPONSE,
  ADD_INTEREST_RESPONSE,
  RESPONSE_OK,
  FORGOT_PASSWORD,
  USER_SIGNUP,
  VERIFY_USER,
  RESPONSE_ERROR,
  GET_CMS,
  PROPERTYLIST,
  GET_NEWSDETAILS,
  NEWSLIST,
  SEARCHPROPERTY,
  DETAILPROPERTY,
} from "./Types";

import { API_PATHS, PATHS } from "../../utils";
import { isLoading, responseFailure } from ".";
import {
  getUnauthedAxios,
  getAxios,
  getMultipartAxios,
} from "../../utils/helper";

export const userSignup = (formData) => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const { data } = await instance.post(
      `${API_PATHS.FREE_SIGNUP_API}`,
      formData
    );

    dispatch({ type: USER_SIGNUP, payload: data });
    return data;
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

/********************************************************* */

export const getVerifyUser = (token) => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const { data } = await instance.post(API_PATHS.USERVERIFY_API, {
      token: token,
    });
    dispatch({ type: VERIFY_USER, payload: data });
  } catch (error) {
    dispatch({ type: RESPONSE_ERROR, payload: error });
  }
};

export const logoutAction = () => async (dispatch) => {
  localStorage.clear();
  dispatch({ type: LOGOUT, payload: undefined });
  window.location.reload(PATHS.LANDINGPAGE);
};

export const userSignin = (formData) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_FAILURE, payload: "" });
  localStorage.removeItem("access_token");
  try {
    const instance = getAxios();
    const { data } = await instance.post(API_PATHS.FREE_SIGNIN_API, formData);
    if (data?.success) {
      localStorage.setItem("access_token", data?.data?.token);
      dispatch({ type: USER_LOGIN, payload: data });
    } else {
      dispatch(responseFailure(data));
    }
  } catch (error) {
    localStorage.removeItem("access_token");
    dispatch(responseFailure(error));
  }
};

/********************************************************* */
export const getUserProfile = () => async (dispatch) => {
  dispatch(isLoading(true));
  try {
    const instance = getAxios();
    const { data } = await instance.post(`${API_PATHS.USER_DETAILBY_ID_API}`);
    dispatch({
      type: GET_ALL_USERS_DETAIL_SUCCESS,
      payload: data,
    });
    dispatch({ type: BASIC_DETAIL_ICON, payload: isAllFilled(data) });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

/********************************************************* */

/********************************************************* */
export const updateUsersBasicDetail = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.patch(
        `${API_PATHS.UPDATE_BASIC_DETAIL_API}`,
        formData
      );
      dispatch({ type: RESPONSE, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const getLandingPage = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const { data } = await instance.get(`${API_PATHS.LANDINGPAGE_API} `);
    dispatch({ type: "LANDINGPAGE", payload: data });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

/********************************************************* */
export const getCMSPage = (slug) => async (dispatch) => {
  try {
    if (slug) {
      const instance = getUnauthedAxios();
      const { data } = await instance.post(API_PATHS.CMS_API, {
        page_slug: slug,
      });
      dispatch({
        type: GET_CMS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

/***********DELETE FILES************/

export const deleteAvatar = (id, fileName) => async (dispatch) => {
  dispatch(isLoading(true));
  try {
    if (id && fileName) {
      const instance = getAxios();
      const { data } = await instance.delete(
        `${API_PATHS.REMOVE_AVATAR_API}${id}${fileName}`
      );
      dispatch({
        type: RESPONSE_OK,
        payload: data,
      });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

/********************************************************* */

export const updateUserProfilePicture =
  (profileImage, id) => async (dispatch) => {
    if (profileImage && id) {
      try {
        const instance = getMultipartAxios();
        const formData = new FormData();
        formData.append("file", profileImage);
        const { data } = await instance.post(
          `${API_PATHS.UPDATE_PROFILE_PICTURE_API}/${id}`,
          formData
        );
        dispatch({
          type: RESPONSE_OK,
          payload: data,
        });
      } catch (error) {
        dispatch(responseFailure(error));
      }
    }
  };

/******* Change Password ************ */

export const changePassword = (password) => async (dispatch) => {
  try {
    if (password) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.CHANGE_PASSWORD_API}`,
        password
      );
      dispatch({
        type: RESPONSE,
        payload: data,
      });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};
/********************************************************* */
export const getNewsDetails = (slug) => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.post(API_PATHS.GETNEWS_DETAIL_STR, {
      slug: slug,
    });
    dispatch({
      type: GET_NEWSDETAILS,
      payload: data,
    });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};
/********Get Grade Level*************** */
export const getNewsListing = () => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.post(`${API_PATHS.GET_NEWS_API}`);
    dispatch({
      type: NEWSLIST,
      payload: data,
    });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const searchProperty = (searchKey) => async (dispatch) => {
  try {
    if (searchKey) {
      const instance = getUnauthedAxios();
      const { data } = await instance.post(API_PATHS.SEARCH_PROPERTY_API, {
        search_key: searchKey,
      });
      dispatch({ type: SEARCHPROPERTY, payload: data });
    } else {
      dispatch({ type: SEARCHPROPERTY, payload: "" });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const getPropertyDetail = (listing_id) => async (dispatch) => {
  try {
    if (listing_id) {
      const instance = getUnauthedAxios();
      const { data } = await instance.post(API_PATHS.GET_PROP_DETAIL_API, {
        listing_id: listing_id,
      });
      dispatch({ type: DETAILPROPERTY, payload: data });
    } else {
      dispatch({ type: DETAILPROPERTY, payload: "" });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const deleteProperty = (propertyId) => async (dispatch) => {
  try {
    if (propertyId) {
      const instance = getAxios();
      const { data } = await instance.post(API_PATHS.DELETE_PROPERTY, {
        id: propertyId,
      });
      dispatch({
        type: RESPONSE,
        payload: data,
      });
    }
  } catch (e) {}
};

export const uploadInterestPic = (profileImage, id) => async (dispatch) => {
  dispatch({ type: ADD_INTEREST_RESPONSE, payload: "" });
  if (profileImage && id) {
    try {
      const instance = getMultipartAxios();
      const formData = new FormData();
      formData.append("file", profileImage);
      const { data } = await instance.post(
        `${API_PATHS.UPLOAD_INTEREST_PICTURE_API}${id}`,
        formData
      );
      dispatch({
        type: UPLOAD_RESPONSE,
        payload: data,
      });
    } catch (error) {
      dispatch(responseFailure(error));
    }
  }
};

export const getPropertyListing = () => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.post(`${API_PATHS.GET_PROPERTY_API}`);
    dispatch({ type: PROPERTYLIST, payload: data });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const addProperty = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(API_PATHS.ADDPROPERTY_API, formData);
      dispatch({ type: RESPONSE_OK, payload: data });
    } else {
      dispatch({ type: RESPONSE_OK, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const updateProperty = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(API_PATHS.UPDATE_PROPERTY, formData);
      dispatch({ type: RESPONSE_OK, payload: data });
    } else {
      dispatch({ type: RESPONSE_OK, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const submitOffer = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getUnauthedAxios();
      const { data } = await instance.post(API_PATHS.SUBMIT_OFR_API, formData);
      dispatch({ type: RESPONSE, payload: data });
    } else {
      dispatch({ type: RESPONSE, payload: "" });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

//FORGOT_API;

export const forgotPassword = (formData) => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const { data } = await instance.post(`${API_PATHS.FORGOT_API}`, formData);
    dispatch({ type: FORGOT_PASSWORD, payload: data });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const resetPassword = (password, token) => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const { data } = await instance.post(API_PATHS.RESET_API, {
      token: token,
      password: password,
    });
    dispatch({ type: FORGOT_PASSWORD, payload: data });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const getPropertyOffer = (id) => async (dispatch) => {
  try {
    if (id) {
      const instance = getAxios();
      const { data } = await instance.post(API_PATHS.PROPERTY_OFFER, {
        id: id,
      });
      dispatch({
        type: "OFFER_LISTING",
        payload: data,
      });
    }
  } catch (e) {}
};

export const setChangeStatus = (id) => async (dispatch) => {
  try {
    if (id) {
      const instance = getAxios();
      const { data } = await instance.post(API_PATHS.CHANGE_STATUS, {
        id: id,
      });
      dispatch({
        type: "RESPONSE",
        payload: data,
      });
    }
  } catch (e) {}
};

export const sendEnquiry = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getUnauthedAxios();
      const { data } = await instance.post(API_PATHS.SEND_ENQUIRY, formData);
      dispatch({ type: RESPONSE, payload: data });
    } else {
      dispatch({ type: RESPONSE, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};
