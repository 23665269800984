/** @format */

import React from "react";
import { Link } from "react-router-dom";
import * as image from "../../resources/images";
import { PATHS } from "../../utils";
const Footer = () => {
  return (
    <footer id="footer" className="section-bg">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="newsletter-content">
                <div className="footer-logo">
                  <h2>UNITWATCH</h2>
                </div>
                <div className="nws-box">
                  <div className="nwsb-text">
                    <h2>Newsletter Subscription</h2>
                    <p>Stay Updated With UnitWatch</p>
                  </div>
                  <a
                    href="javascriptL:void(0)"
                    className="black-btn d-inline-flex align-items-center justify-content-center"
                  >
                    <span>Subscribe</span>
                    <img src={image.whiteArrow} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>About UNITWATCH</h3>
              <p>
                Aiusmod tempor incididunt labore dnim ad minim veniam quis
                nostrsd exercitation ullamco.
              </p>

              <div className="footer-social">
                <a href="javascript:void(0)">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="javascript:void(0)">
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a href="javascript:void(0)">
                  <i className="fa-brands fa-google-plus-g"></i>
                </a>
                <a href="javascript:void(0)">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
                <a href="javascript:void(0)">
                  <i className="fa-brands fa-vimeo-v"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 footer-links gbtext">
              <h4>links</h4>
              <ul>
                <li>
                  <router-link to="/about">About UnitWatch</router-link>
                </li>
                <li>
                  <Link to={PATHS.NEWS}>Latest News</Link>
                </li>
                <li>
                  <router-link to="/process">Our Process</router-link>
                </li>
                <li>
                  <router-link to="/condition">Terms & Conditions</router-link>
                </li>
                <li>
                  <router-link to="/protection">
                    Protections & Coverages
                  </router-link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>explore UNITWATCH</h4>
              <ul>
                <li>
                  <a href="javascript:void(0)">Information</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Pricing</a>
                </li>
                <li>
                  <a href="javascript:void(0)">User Guide</a>
                </li>
                <li>
                  <a href="javascript:void(0)">FAQ’s</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 get-in-touch">
              <h4>Get In Touch</h4>
              <div className="git-sc">
                <div className="git-icon">
                  <img src={image.supportIcon} alt="" />
                </div>
                <div className="git-text">
                  <span>For Support</span>
                  <strong>+1 (236) 799 5500 / 6600</strong>
                </div>
              </div>
              <div className="git-sc">
                <div className="git-icon">
                  <img src={image.hourIcon} alt="" />
                </div>
                <div className="git-text">
                  <span>Office Hours</span>
                  <strong>Mon - Sat 8am to 6pm</strong>
                </div>
              </div>
              <div className="git-sc">
                <div className="git-icon">
                  <img src={image.mailIcon} alt="" />
                </div>
                <div className="git-text">
                  <span>Send Us Email</span>
                  <strong>info@unitwatch.com</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <div className="container py-4">
          <div className="row">
            <div className="col-md-12">
              <div className="cp-content">
                <p>(c) 2022 UNITWATCH, ALL RIGHTS RESERVED.</p>
                <div className="cp-links">
                  <Link to={PATHS.CMSPAGE_STR + "privacy-policy"}>
                    Terms & Conditions
                  </Link>
                  <span>|</span>
                  <a href="javascript:void(0)">Sitemap</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
