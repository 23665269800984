/** @format */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import { isLoading, resetGuestResponse } from "../../redux/actions";
import {
  deleteProperty,
  getPropertyListing,
  setChangeStatus,
} from "../../redux/actions/APIs";
import { PATHS } from "../../utils";
import WebLayout from "../layout/WebLayout";
import DataTable from "react-data-table-component";
import Home from "../Home";
import { useCallback } from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const PropertyListing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();
  const { response, propertyListData } = useSelector(
    (state) => state.collections
  );
  const clickHandler = (d) => {
    history.push(PATHS.OFFERLIST_STR + d.id);
  };
  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Address",
      selector: (row) => row.address,
    },
    {
      name: "Total Offers",
      selector: (row) => row.address,
    },
    {
      cell: (row) =>
        row?.is_active ? (
          <button
            key={Math.random()}
            className="btn btn-sm btn-outline-success"
            onClick={() => changeStatus(row)}
            id={row.ID}
          >
            <i key={row?.id} className="fa fa-check-square"></i> Active
          </button>
        ) : (
          <button
            key={Math.random()}
            className="btn btn-sm btn-outline-warning"
            onClick={() => changeStatus(row)}
            id={row.ID}
          >
            <i key={row?.id} className="fa fa-square-o"></i> Inactive
          </button>
        ),
      selector: (row) => row.is_active,
      name: "Status",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => (
        <button
          className="btn btn-sm btn-outline-warning"
          onClick={() => clickHandler(row)}
          id={row.ID}
        >
          <i className="fa fa-hand-o-right"></i> Offers
        </button>
      ),
      name: "View Offers",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => (
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => redirectUpdate(row)}
        >
          <i className="fa fa-pencil"></i> Update
        </button>
      ),
      name: "Update",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const changeStatus = (item) => {
    dispatch(setChangeStatus(item?.id));
  };
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [data, setData] = useState(propertyListData?.data);
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  useEffect(() => {
    if (propertyListData) {
      setData(propertyListData?.data);
    }
  }, [propertyListData]);

  useEffect(() => {
    if (response) {
      dispatch(getPropertyListing());
    }
  }, [response]);

  useEffect(() => {
    dispatch(getPropertyListing());
  }, []);

  const redirectUpdate = (id) => {
    history.push(PATHS.EDITPROPERTY_STR + id?.listing_id);
  };
  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.title
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        selectedRows.map((vl, ky) => {
          dispatch(deleteProperty(vl?.id));
        });
      }
    };

    return (
      <button
        key="delete"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
      >
        Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  return (
    <Home>
      <section class="breadcrumbs section-bg-gray">
        <div class="container-fluid px-3 px-md-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Property Listing</h2>
            <ol>
              <li>
                <a href="#">Home</a>
              </li>
              <li>Property Listing</li>
            </ol>
          </div>
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <Link to={PATHS.ADDPROPERTY} className="btn btn-success btn-right">
            <i className="fa fa-plus-square"></i> Add Property
          </Link>
          <DataTable
            title="Desserts"
            columns={columns}
            data={data}
            selectableRows
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            pagination
            dense
          />
        </div>
      </section>
    </Home>
  );
};

export default PropertyListing;
