/** @format */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as image from "../../resources/images";
import WebLayout from "../layout/WebLayout";

import { getLandingPage, searchProperty } from "../../redux/actions/APIs";
import { PATHS } from "../../utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { htmlStrip, textTrim } from "../../utils/helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SearchResult = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { landingData, searchPropertyData } = useSelector(
    (state) => state.collections
  );

  useEffect(() => {
    dispatch(getLandingPage());
  }, []);
  console.log(props?.history?.location?.state?.data, "props");

  const onTextChanged = (e) => {
    e.preventDefault();
    const value = document.getElementById("search").value;
    dispatch(searchProperty(value));
  };
  let propertyData = [];
  if (props?.history?.location?.state) {
    propertyData = props?.history?.location?.state?.data?.data?.property_list;
  } else {
    if (searchPropertyData) {
      propertyData = searchPropertyData?.data?.data?.property_list;
    } else {
      console.log(signinresponse, "yeeee");
      history.push(PATHS.HOME);
    }
  }
  return (
    <WebLayout>
      <section id="hero" class="prop-search-banner d-flex align-items-center">
        <div class="container">
          <h2>Property List Search</h2>
          <div class="banner-search p-3">
            <form class="">
              <div class="inner-form">
                <div class="input-field second-wrap">
                  <input
                    type="text"
                    id="search"
                    placeholder="Find My Property"
                    defaultValue={props?.history?.location?.state?.keyword}
                  />
                </div>
                <div class="input-field third-wrap">
                  <button
                    class="btn-search"
                    type="text"
                    onClick={onTextChanged}
                  >
                    <img src={image.searchIcon} alt="" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="news" class="news section-bg-gray prp-search">
          <div class="container">
            <div class="row g-3">
              {propertyData &&
                propertyData.map((val, ky) => (
                  <div class="col-lg-4 col-md-6">
                    <div class="box">
                      <div class="lnb-pic">
                        <div class="tag-line">{val?.category}</div>
                        {/* <img src={image.noImage} alt="" class="img-fluid" /> */}
                        {val?.listing_image.length > 0 ? (
                          <img
                            src={
                              "https://app.unitwatch.com/" +
                              val?.listing_image[0]?.image_path
                            }
                            alt=""
                            class="img-fluid"
                          />
                        ) : (
                          <img src={image.noImage} alt="" class="img-fluid" />
                        )}
                      </div>
                      <div class="news-content">
                        <div class="posted-by">
                          <strong>22</strong> |
                          <span>
                            By <a href="javascript:void(0)">Antek Admin</a>
                          </span>
                        </div>
                        <h3>{val?.title}</h3>
                        <div>{textTrim(htmlStrip(val?.description), 150)}</div>
                      </div>
                      <div class="news-read-more">
                        <Link to={PATHS.PROPERTYDETAIL_STR + val?.listing_id}>
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </main>
    </WebLayout>
  );
};

export default SearchResult;
