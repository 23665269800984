/** @format */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { isLoading, resetGuestResponse } from "../../redux/actions";
import { getNewsDetails } from "../../redux/actions/APIs";

import WebLayout from "../layout/WebLayout";

const NewsDetails = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const { newsDetailsData } = useSelector((state) => state.collections);
  useEffect(() => {
    dispatch(getNewsDetails(param?.slug));
  }, []);

  return (
    <WebLayout>
      <section class="breadcrumbs section-bg-gray">
        <div class="container-fluid px-3 px-md-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>News Details</h2>
            <ol>
              <li>
                <a href="#">Home</a>
              </li>
              <li>Inner Page</li>
            </ol>
          </div>
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 d-flex">
              <div className="box shadow">
                <div className="lnb-pic">
                  <img
                    src={
                      "https://app.unitwatch.com/" +
                      newsDetailsData?.data?.news_image
                    }
                    className="img-fluid"
                  />
                </div>
                <div
                  className="news-content"
                  dangerouslySetInnerHTML={{
                    __html:
                      newsDetailsData && newsDetailsData?.data?.description,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
};

export default NewsDetails;
