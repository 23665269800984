import React, { useState, useEffect } from "react";
import { useForm } from "../../utils/Packages";
import { useDispatch, useSelector } from "react-redux";

import * as image from "../../resources/images";
import { userSignin } from "../../redux/actions/APIs";
import { isLoading, showModal } from "../../redux/actions";

import { EMAIL_REGEX } from "../../utils/Regex";
import { MSG } from "../../utils/Messages";
import { Modal, Button } from "../../utils/Packages";

const SignInPG = () => {
  const { loading, error } = useSelector((state) => state.collections);
  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onTouched",
  });
  const { errors } = formState;

  const dispatch = useDispatch();

  const [type, setType] = useState("password");

  const handleChecked = (e) => {
    console.log(e.target.checked, "checked");
  };

  useEffect(() => {
    if (
      localStorage.getItem("username") !== "" &&
      localStorage.getItem("checkbox")
    ) {
      setValue("username", localStorage.getItem("username"));
    }
  }, []);

  const _onSubmit = (values) => {
    const rmCheck = document.getElementById("rememberMe"),
      emailInput = document.getElementById("username");
    if (rmCheck.checked && emailInput.value !== "") {
      localStorage.setItem("username", emailInput.value);
      localStorage.setItem("checkbox", rmCheck.value);
    } else {
      localStorage.setItem("username", "");
      localStorage.setItem("checkbox", "");
    }
    dispatch(isLoading(true));
    dispatch(userSignin(values));
  };

  const showHide = () => {
    setType(type === "password" ? "text" : "password");
  };

  const hideSign = () => {
    dispatch(showModal());
  };
  return (
    <>
      <Modal show={true} onHide={() => hideSign()} className="AddChildPopup">
        <div id="signin" class="in-up-form">
          <Modal.Header>
            <div className="modal-title flex h4">
              {/*<img src={image.Signinicon} alt="" className="mr-2" />*/}
               <i className="fa-solid fa-id-card mr-2 fontawsomeicon"></i> 
              <h3>Sign In </h3>{" "}
            </div>
            <Button data-dismiss="modal" onClick={() => hideSign()}>
              <i className="fa-solid fa-xmark m-0"></i>
            </Button>
          </Modal.Header>
          <div className="wrapper mx-auto signin_box_styl p-3">
            <form
              name="freesignin"
              className="bg-white content"
              onSubmit={handleSubmit(_onSubmit)}
            >
              <Modal.Body className="pt-0 px-0">
                <div className="input-group mb-2">
                  <div className="row">
                    <div className="col-md-4"> 
                      <label>
                        Email/Username<span className="mandatoryField">*</span>
                      </label>  
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Email/Username"
                          className={`form-control ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          id="username"
                          {...register("email", {
                            required: {
                              value: true,
                              message: MSG.EMAILREQ,
                            },
                            pattern: {
                              value: EMAIL_REGEX,
                              message: MSG.INVEMAILREQ,
                            },
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors.email?.message}
                        </div>
                      </div> 
                    </div>
                  </div>
                </div>
                <div className="input-group mb-2 position-relative">
                  <div className="row">
                    <div className="col-md-4">
                      <label>
                        Password<span className="mandatoryField">*</span>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <input
                          className={`form-control ${
                            errors.password ? "is-invalid" : ""
                          }`}
                          placeholder="Password"
                          {...register("password", {
                            required: {
                              value: true,
                              message: MSG.PASSREQ,
                            },
                          })}
                          type={type}
                          id={"password"}
                        />
                        <div className="invalid-feedback">
                          {errors.password?.message}
                        </div>
                      </div>
                      <div onClick={() => showHide()}>
                      {type === "password" ? (
                        <span className="PasswordeyeIcon">
                          <i className="fa-solid fa-eye-slash"></i>
                        </span>
                      ) : (
                        <p>
                          <span className="PasswordeyeIcon">
                            <i className="fa-solid fa-eye"></i>
                          </span>
                        </p>
                      )}
                    </div>
                    </div>
                  </div>
                </div>

                <div className="rem-forgot ">
                  <label htmlFor="rememberMe" className="rembrme">
                    <input
                      type="checkbox"
                      className="mr-2"
                      id="rememberMe"
                      onChange={handleChecked}
                    />
                    <span className=""> Remember Me</span>
                  </label>

                  {/* <NavLink to={PATHS.FORGOTPASSWORD} className="">
                  Forgot Password?
                </NavLink> */}
                  <span
                    onClick={() =>
                      dispatch(showModal({ type: "forgotPassword" }))
                    }
                    className="forgot_password pointer"
                  >
                    {" "}
                    Forgot Password?
                  </span>
                </div>
              </Modal.Body>

              <div className="d-flex align-items-center justify-content-end">
                {loading ? (
                  <button
                    className="black-btn btn-small"
                    key={Math.random()}
                  >
                    <span className="RounAnimation"></span> Please Wait...
                  </button>
                ) : (
                  <button
                    key={Math.random()}
                    type="submit"
                    className="black-btn btn-small"
                  >
                    <i className="fa-solid fa-paper-plane mr-2"></i> Sign In
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SignInPG;
