/** @format */

import React, { useEffect, useState } from "react";
import { useForm, moment } from "../../utils/Packages";

import Home from "../Home";

import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfile,
  updateUsersBasicDetail,
} from "../../redux/actions/APIs";
import AddressForm from "../controls/AddressForm";

import { useHistory } from "react-router-dom";

import { EMAIL_REGEX, CHAR_REGEX, MOBILE_REGEX } from "../../utils/Regex";

import { MSG } from "../../utils/Messages";

const BasicDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    setValue,
    handleSubmit,
    control,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const { getSelectedUser, response, loggedInUser, userDetails } = useSelector(
    (state) => state.collections
  );

  const [showForm, setShowForm] = useState(false);

  const [add, setAdd] = useState("");

  useEffect(() => {
    if (history?.location?.state) {
      setShowForm(history?.location?.state?.edit);
    }
  }, [history?.location?.state]);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  const _onSubmit = (values) => {
    let formData = {
      name: values.name,
      lastName: values.lastName,

      phone: values.phone,
      address: values.address,
      country: values.country,
      state: values.state,
      city: values.city,
      zip_code: values.zip_code,
    };

    dispatch(updateUsersBasicDetail(formData));
  };

  const handleSetvalue = () => {
    if (userDetails?.data) {
      const fields = [
        "name",
        "lastName",
        "email",
        "state",
        "city",
        "country",
        "zip_code",
        "address",
        "phone",
      ];

      fields.forEach((field) => {
        if (userDetails?.data[field] && userDetails?.data[field] != "null") {
          setValue(field, userDetails?.data[field]);
        } else {
          setValue(field, "");
        }
      });
    }
  };

  useEffect(() => {
    if (userDetails) {
      console.log(userDetails?.data);
      handleSetvalue();
      handleErrors();
    }
  }, [userDetails?.data]);

  const handleErrors = () => {
    clearErrors("name");

    clearErrors("lastName");
    clearErrors("dateOfBirth");
    clearErrors("email");
    clearErrors("state");
    clearErrors("city");
    clearErrors("country");
    clearErrors("zip");
    clearErrors("address");

    clearErrors("mobile");
  };

  return (
    <Home>
      <section class="breadcrumbs section-bg-gray">
        <div class="container-fluid px-3 px-md-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>User Profile</h2>
            <ol>
              <li>
                <a href="#">Home</a>
              </li>
              <li>User Profile</li>
            </ol>
          </div>
        </div>
      </section>
      <section className="inner-page-content profile-edit-form">
        <div className="container">
          <div className="row justify-content-center">
            {showForm ? (
              <div className="wrapper mx-auto">
                <form
                  className="bg-white content"
                  name="basicdetailform"
                  onSubmit={handleSubmit(_onSubmit)}
                >
                  <div className="form-title">
                    <h3 data-toggle="collapse">
                      <i className="fa-light fa-square-info"></i>Basic Details{" "}
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pos-rel mb-3">
                      <label>
                        First Name<span className="mandatoryField">*</span>
                      </label>
                      <input
                        type="text"
                        maxLength="15"
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        {...register("name", {
                          required: {
                            value: true,
                            message: MSG.FNAMEREQ,
                          },
                          pattern: {
                            value: CHAR_REGEX,
                            message: MSG.VALIDINFOREQ,
                          },
                          maxLength: {
                            value: 14,
                            message: MSG.MAX15CHREQ,
                          },
                        })}
                      />
                      {errors.name && (
                        <p className="text-danger">{errors.name.message}</p>
                      )}
                    </div>{" "}
                    <div className="col-md-6 pos-rel mb-3">
                      <label className="mb-2">
                        Last Name<span className="mandatoryField">*</span>
                      </label>

                      <input
                        type="text"
                        maxLength="15"
                        className={`form-control ${
                          errors.lastName ? "is-invalid" : ""
                        }`}
                        {...register("lastName", {
                          required: {
                            value: true,
                            message: MSG.LNAMEREQ,
                          },
                          pattern: {
                            value: CHAR_REGEX,
                            message: MSG.VALIDINFOREQ,
                          },
                          maxLength: {
                            value: 14,
                            message: MSG.MAX15CHREQ,
                          },
                        })}
                      />

                      {errors.lastName && (
                        <p className="text-danger">{errors.lastName.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pos-rel mb-3">
                      <label className="mb-2">
                        Email
                        <span className="mandatoryField">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        {...register("email", {
                          pattern: {
                            value: EMAIL_REGEX,
                            message: MSG.INVEMAILREQ,
                          },
                        })}
                      />
                    </div>
                    {errors.email && (
                      <p className="text-danger">{errors.email.message}.</p>
                    )}

                    <div className="col-md-6 pos-rel mb-3">
                      <label className="mb-2">
                        Mobile
                        <span className="mandatoryField">*</span>
                      </label>

                      <input
                        type="text"
                        maxLength="15"
                        className={`form-control ${
                          errors.mobile ? "is-invalid" : ""
                        }`}
                        {...register("phone", {
                          onChange: (e) => mobileNumberFormat(e),
                          required: {
                            value: true,
                            message: MSG.MOBNUMREQ,
                          },
                          pattern: {
                            value: MOBILE_REGEX,
                            message: MSG.INVMOBNUMREQ,
                          },
                        })}
                      />

                      {errors.mobile && (
                        <p className="text-danger">{errors.mobile.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="form-title margintitle w-100">
                    <h3
                      data-toggle="collapse"
                      className="flex justify-content-between"
                    >
                      <span>
                        {" "}
                        <i className="fa-light fa-address-book mr-2"></i>
                        Address{" "}
                      </span>
                      {/* loggedInUser?.parent?.address1 === null  */}
                    </h3>
                  </div>

                  <div className="">
                    {showForm && (
                      <AddressForm
                        showForm={showForm}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        addressData={userDetails?.data}
                        showAddress={false}
                        setAdd={setAdd}
                        add={add}
                        control={control}
                        getValues={getValues}
                        // showCheckout={false}
                      />
                    )}
                  </div>

                  <div className="mt-2">
                    <div className="form-group d-flex">
                      <button
                        type="button"
                        className="btn btn-warning btn-login d-block mr-2"
                        onClick={() => {
                          handleSetvalue();
                          setShowForm(false);

                          handleErrors();
                          setAdd("");
                          setAddLear("");
                        }}
                      >
                        <i className="fa-solid fa-arrow-left"></i> Back
                      </button>

                      <div className="buttonDistribotion">
                        <button
                          type="submit"
                          className="btn btn-primary btn-login d-block mr-2 "
                        >
                          <i className="fa-solid fa-paper-plane"></i> Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div className="wrapper mx-auto">
                <div className="form-title">
                  <h3 data-toggle="collapse">
                    <i className="fa-light fa-square-info mr-2"></i>Basic
                    Details{" "}
                  </h3>
                </div>
                <div className="row">
                  <div className="col-md-6 pos-rel mb-3">
                    <label className="mb-2">First Name</label>
                    <input
                      type="text"
                      className={`form-control`}
                      {...register("name", {
                        required: true,
                      })}
                      disabled
                    />
                    <span
                      className="UserEditForm"
                      onClick={() => {
                        handleSetvalue();
                        setShowForm(true);
                      }}
                    >
                      <i className="fa fa-pencil"></i>
                    </span>
                  </div>

                  <div className="col-md-6 pos-rel mb-3">
                    <label className="mb-2">Last Name</label>
                    <input
                      type="text"
                      className={`form-control`}
                      {...register("lastName", {
                        required: true,
                      })}
                      disabled
                    />
                    <span
                      className="UserEditForm"
                      onClick={() => {
                        handleSetvalue();
                        setShowForm(true);
                      }}
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 pos-rel mb-3">
                    <label className="mb-2">Email</label>
                    <input
                      type="text"
                      className={`form-control`}
                      {...register("email", {
                        required: {
                          value: true,
                          message: MSG.EMAILREQ,
                        },
                        pattern: {
                          value: EMAIL_REGEX,
                          message: MSG.INVEMAILREQ,
                        },
                      })}
                      disabled
                    />
                    <span
                      className="UserEditForm"
                      onClick={() => setShowForm(true)}
                    >
                      {" "}
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </div>

                  <div className="col-md-6 pos-rel mb-3">
                    <label className="mb-2">Mobile</label>
                    <div className="mobileManage">
                      <input
                        type="text"
                        className={`form-control`}
                        {...register("phone")}
                        disabled
                      />
                    </div>
                    <span
                      className="UserEditForm"
                      onClick={() => setShowForm(true)}
                    >
                      {" "}
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </div>
                </div>

                <div key={Math.random()} className="w-100">
                  <div className="form-title margintitle">
                    <h3 data-toggle="collapse">
                      <i className="fa-light fa-address-book mr-2"></i>Address{" "}
                    </h3>
                  </div>
                  <div className=" ">
                    <div className="row">
                      <div className="col-md-6 pos-rel mb-3">
                        <label className="mb-2">Address</label>
                        <input
                          type="text"
                          className={`form-control`}
                          {...register("address")}
                          disabled
                        />
                        <span
                          className="UserEditForm"
                          onClick={() => setShowForm(true)}
                        >
                          {" "}
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                      </div>

                      <div className="col-md-6 pos-rel mb-3">
                        <label className="mb-2">City</label>
                        <input
                          type="text"
                          className={`form-control`}
                          {...register("city")}
                          disabled
                        />
                        <span
                          className="UserEditForm"
                          onClick={() => setShowForm(true)}
                        >
                          {" "}
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 pos-rel mb-3">
                        <label className="mb-2">State</label>
                        <input
                          type="text"
                          className={`form-control`}
                          {...register("state")}
                          disabled
                        />
                        <span
                          className="UserEditForm"
                          onClick={() => setShowForm(true)}
                        >
                          {" "}
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                      </div>

                      <div className="col-md-6 pos-rel mb-3">
                        <label className="mb-2">Country</label>

                        <input
                          type="text"
                          className={`form-control`}
                          {...register("country")}
                          disabled
                        />
                        <span
                          className="UserEditForm"
                          onClick={() => setShowForm(true)}
                        >
                          {" "}
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 pos-rel mb-3">
                        <label className="mb-2">Zipcode</label>
                        <div className="l">
                          <input
                            type="text"
                            className={`form-control`}
                            placeholder="Zipcode"
                            {...register("zip_code")}
                            disabled
                          />
                        </div>
                        <span
                          className="UserEditForm"
                          onClick={() => setShowForm(true)}
                        >
                          {" "}
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Home>
  );
};

export default BasicDetails;
//891
