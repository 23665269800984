/** @format */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { isLoading, resetGuestResponse } from "../../redux/actions";
import { getCMSPage } from "../../redux/actions/APIs";

import WebLayout from "../layout/WebLayout";

const CmsPage = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const { response, cmsData } = useSelector((state) => state.collections);
  useEffect(() => {
    dispatch(getCMSPage(param?.slug));
  }, []);

  return (
    <WebLayout>
      <section class="breadcrumbs section-bg-gray">
        <div class="container-fluid px-3 px-md-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>{cmsData?.data[0]?.title}</h2>
            <ol>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>{cmsData?.data[0]?.title}</li>
            </ol>
          </div>
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 d-flex">
              <div
                dangerouslySetInnerHTML={{
                  __html: cmsData && cmsData?.data[0]?.content,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
};

export default CmsPage;
