/** @format */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { PATHS } from "../../utils";
import { getNewsListing } from "../../redux/actions/APIs";
import * as image from "../../resources/images";
import { textTrim, htmlStrip } from "../../utils/helper";
import WebLayout from "../layout/WebLayout";

const News = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const { newsListing } = useSelector((state) => state.collections);
  useEffect(() => {
    dispatch(getNewsListing());
  }, []);
  console.log(newsListing?.data);
  return (
    <WebLayout>
      <section class="breadcrumbs section-bg-gray">
        <div class="container-fluid px-3 px-md-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Latest News & Updates</h2>
            <ol>
              <li>
                <a href="#">Home</a>
              </li>
              <li>Contact Us </li>
            </ol>
          </div>
        </div>
      </section>

      <section id="news" class="news section-bg-gray">
        <div class="container">
          <div class="row">
            {newsListing?.data &&
              newsListing?.data.map((val, ky) => (
                <div class="col-lg-4 col-md-6">
                  {console.log(val?.slug)}
                  <div class="box">
                    <div class="lnb-pic">
                      <div class="tag-line">{val?.category}</div>
                      <img
                        src={"https://app.unitwatch.com/" + val.news_image}
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="news-content">
                      <div class="posted-by">
                        <strong>22</strong> |
                        <span>
                          By <a href="javascript:void(0)">Antek Admin</a>
                        </span>
                      </div>
                      <h3>{val?.title}</h3>
                      <div>{textTrim(htmlStrip(val?.description), 150)}</div>
                    </div>
                    <div class="news-read-more">
                      <Link to={PATHS.NEWSDETAILS_STR + val?.slug}>
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </WebLayout>
  );
};

export default News;
