/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { showModal } from "../../redux/actions";
import { PATHS } from "../../utils";

const Topbar = () => {
  const dispatch = useDispatch();
  const { alluserdetails, isAuth } = useSelector((state) => state.collections);

  const showModalBox = (obj) => {
    dispatch(showModal(obj));
  };
  return (
    <header id="header" class="d-flex align-items-center inner-header header">
      <div class="container-fluid d-flex align-items-center justify-content-between">
        <nav class="navbar navbar-expand-lg w-100">
          <div class="container-fluid">
            <div class="d-flex align-items-center justify-content-start">
              <a class="navbar-brand" href="#">
                UNITWATCH
              </a>
            </div>
            <div
              class="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item">
                  <Link
                    class="nav-link active"
                    aria-current="page"
                    to={PATHS.HOME}
                  >
                    Home
                  </Link>
                </li>
                <li class="nav-item">
                  <NavLink class="nav-link" to={"#"}>
                    About Us
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink class="nav-link" to={"#"}>
                    How it works
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink class="nav-link" to={PATHS.CONTACTUS}>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
            <div class="sign-links">
              <ul>
                <li onClick={() => showModalBox({ type: "SignIn" })}>
                  Sign In
                </li>
                <li class="px-2 underline-none">Or</li>

                <li
                  className="signup_styl"
                  onClick={() => showModalBox({ type: "SignUp" })}
                >
                  Sign Up
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Topbar;
