/** @format */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import { deleteProperty, getPropertyOffer } from "../../redux/actions/APIs";
import { PATHS } from "../../utils";
import WebLayout from "../layout/WebLayout";
import DataTable from "react-data-table-component";
import Home from "../Home";
import { useCallback } from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const PropertyOffer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();
  const { offerListingData } = useSelector((state) => state.collections);
  console.log(offerListingData?.data);
  const clickHandler = (d) => {
    history.push(PATHS.OFFERLIST_STR + d.id);
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Offer Amount",
      selector: (row) => row.offer_price,
    },
    {
      name: "Message",
      selector: (row) => row.message,
    },
  ];

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [data, setData] = useState(offerListingData?.data?.listing_offers);
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  useEffect(() => {
    if (offerListingData) {
      setData(offerListingData?.data?.listing_offers);
    }
  }, [offerListingData]);

  useEffect(() => {
    if (param?.id) {
      dispatch(getPropertyOffer(param?.id));
    }
  }, [param]);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.title
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        selectedRows.map((vl, ky) => {
          dispatch(deleteProperty(vl?.id));
        });
      }
    };

    return (
      <button
        key="delete"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
      >
        Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);

  return (
    <Home>
      <section class="breadcrumbs section-bg-gray">
        <div class="container-fluid px-3 px-md-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>{offerListingData?.data?.title} offers</h2>
            <ol>
              <li>
                <a href="#">Home</a>
              </li>
              <li>Property Offer Listing</li>
            </ol>
          </div>
        </div>
      </section>
      <section className="inner-page-content">
        <div className="container">
          <button onClick={() => history.goBack()} className="btn btn-success">
            <i className="fa fa-angle-double-left"></i>
            Go Back
          </button>
          <DataTable
            title=""
            columns={columns}
            data={data}
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            pagination
            dense
          />
        </div>
      </section>
    </Home>
  );
};

export default PropertyOffer;
