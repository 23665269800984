/** @format */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams, useHistory } from "react-router-dom";

import { getVerifyUser } from "../../redux/actions/APIs";
import * as image from "../../resources/images";
import { PATHS } from "../../utils";
const VerifyUser = () => {
  const { verifyUserData, responseError } = useSelector(
    (state) => state.collections
  );
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [viewHtml, setViewHtml] = useState("");
  useEffect(() => {
    if (params?.token) {
      console.log(params?.token, "bbbb");
      dispatch(getVerifyUser(params?.token));
    }
  }, []);
  useEffect(() => {
    if (responseError) {
      setViewHtml("error");
      setTimeout(() => {
        // history.push(PATHS.HOME);
      }, 5000);
    }
  }, [responseError]);
  useEffect(() => {
    if (verifyUserData) {
      setViewHtml("success");
      setTimeout(() => {
        history.push(PATHS.HOME);
      }, 5000);
    }
  }, [verifyUserData]);

  return (
    <div>
      {viewHtml === "error" && (
        <div>
          <p className="text-left ml-3 mt-3 pl-3 logoerror">UNITWATCH</p>
          <div className="Successpage text-center">
            <h3 className=" mb-3">
              Your Token has been <span>Expired</span>{" "}
            </h3>
            <h2 className="">Or is no longer active.</h2>
            <div className="Successimsge">
              <img src={image.error_page_img} alt="..." />
            </div>
            <div className="ErrorDescription w-100">
              <NavLink to={PATHS.HOME}>
                <i className=" icon icon-dapulse-home  h4 mb-0 mr-2"></i>Go To
                Home
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {viewHtml === "success" && (
        <>
          <p className="text-left ml-3 mt-3 pl-3 logoerror">UNITWATCH</p>
          <div className="Successpage text-center">
            <h3 className=" mb-3">
              Your Token has been Verified{" "}
              <span className="greencolo">SuccessFully</span>{" "}
            </h3>
            <h2 className="">Thanks</h2>
            <div className="Successimsge">
              <img src={image.success_page_img} alt="..." />
            </div>
            <div className="ErrorDescription w-100">
              <NavLink to={PATHS.HOME}>
                <i className=" icon icon-dapulse-home  h4 mb-0 mr-2"></i>Go To
                Home
              </NavLink>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VerifyUser;
