/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, yupResolver, Yup } from "../../utils/Packages";

import Home from "../Home";
import * as image from "../../resources/images";

import { changePassword } from "../../redux/actions/APIs";
import { breadcrumb } from "../../redux/actions";
import {
  SPECIAL_CHAR,
  GET_LOWER_CASE,
  GET_UPPER_CASE,
  GET_NUMBER,
} from "../../utils/Regex";
import { MSG } from "../../utils/Messages";

const ChangePassword = () => {
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(MSG.NEWPASSREQ)
      .matches(RegExp("(.*[a-z].*)"), MSG.STRNGPWD)
      .matches(RegExp("(.*[A-Z].*)"), MSG.STRNGPWD)
      .matches(RegExp("(.*\\d.*)"), MSG.STRNGPWD)
      .matches(RegExp('[!@#$%^&*(),.?":{}|<>_]'), MSG.STRNGPWD)
      .min(8, "Password Length must be 8 characters or more"),
    confirmPassword: Yup.string()
      .required(MSG.CPASSREQ)
      .oneOf([Yup.ref("newPassword")], "Passwords does not match"),
  });

  const { register, handleSubmit, reset, formState, getValues } = useForm({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;
  let dispatch = useDispatch();
  const [eightState, getEightState] = useState(false);
  const [specialCharState, getSpecialCharState] = useState(false);
  const [upperCaseComplexity, getUpperCaseComplexity] = useState(false);
  const [lowerCaseComplexity, getLowerCaseComplexity] = useState(false);
  const [numberComplexity, getNumberComplexity] = useState(false);
  useEffect(() => {
    dispatch(
      breadcrumb({ title: "Change Password", icon: image.changepassword })
    );
  }, []);
  const _onSubmit = (value) => {
    let formData = {
      password: value.newPassword,
    };

    dispatch(changePassword(formData));
    getEightState(false);
    getSpecialCharState(false);
    getUpperCaseComplexity(false);
    getLowerCaseComplexity(false);
    getNumberComplexity(false);
    reset();
  };
  const resetForm = () => {
    getEightState(false);
    getSpecialCharState(false);
    getUpperCaseComplexity(false);
    getLowerCaseComplexity(false);
    getNumberComplexity(false);
    reset();
  };
  const [type, setType] = useState("password");
  const [confirmType, setConfirmType] = useState("password");
  const toggleShow = () => {
    setType(type === "text" ? "password" : "text");
  };
  const confirmPasswordShow = () => {
    setConfirmType(confirmType === "text" ? "password" : "text");
  };

  const typePassword = (flag) => {
    if (flag === "New Password") {
      let item = document.getElementById("newPassword").value;
      if (item.length >= 8) {
        getEightState(true);
      } else {
        getEightState(false);
      }
      if (SPECIAL_CHAR.test(item)) {
        getSpecialCharState(true);
      } else {
        getSpecialCharState(false);
      }

      if (GET_LOWER_CASE.test(item)) {
        getLowerCaseComplexity(true);
      } else {
        getLowerCaseComplexity(false);
      }

      if (GET_UPPER_CASE.test(item)) {
        getUpperCaseComplexity(true);
      } else {
        getUpperCaseComplexity(false);
      }
      if (GET_NUMBER.test(item)) {
        getNumberComplexity(true);
      } else {
        getNumberComplexity(false);
      }
    }
  };

  return (
    <Home>
      <section class="breadcrumbs section-bg-gray">
        <div class="container-fluid px-3 px-md-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Change Password</h2>
            <ol>
              <li>
                <a href="#">Home</a>
              </li>
              <li>Change Password</li>
            </ol>
          </div>
        </div>
      </section>
      <div className="LeftbarPannel p-0" id="changePassword">
        <div className="PannelContent basicdetailsform px-5">
          <div className="wrapper changepasword">
            <div className="Accountrelated">
              <form
                name="changepasswordform"
                className="content"
                onSubmit={handleSubmit(_onSubmit)}
              >
                <div className="flex flex-wrap align-items-start">
                  <div className="input-group mb-2">
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="newPassword">
                          New Password<span className="mandatoryField">*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <div className=" mb15 " key={type}>
                          <div className="">
                            <span
                              htmlFor="password"
                              className="form-label PasswordeyeIcon"
                              onClick={() => toggleShow()}
                            >
                              <i
                                className={`fa-solid ${
                                  type === "password"
                                    ? "fa-eye-slash"
                                    : "fa-eye"
                                } `}
                              ></i>
                            </span>

                            <input
                              {...register("newPassword", {
                                required: true,
                              })}
                              type={type}
                              id="newPassword"
                              onChange={() => typePassword("New Password")}
                              className={`form-control ${
                                errors.newPassword && "is-invalid"
                              }`}
                            />
                          </div>
                        </div>
                        {errors.newPassword && (
                          <p className="text-danger">
                            {errors.newPassword?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-group" key="confirmPassword">
                    <label htmlFor="confirmpassword">
                      Confirm Password<span className="mandatoryField">*</span>
                    </label>
                    <div className=" mb15 " key={confirmType}>
                      <div className="">
                        <span
                          htmlFor="confirmPassword"
                          className="form-label PasswordeyeIcon"
                        >
                          {getValues("confirmPassword") !== "" &&
                            getValues("confirmPassword") !== undefined &&
                            (getValues("newPassword") ===
                            getValues("confirmPassword") ? (
                              <span>
                                <i className="fa-solid fa-check text-success" />
                              </span>
                            ) : (
                              <span>
                                <i className="fa-solid fa-xmark text-danger" />
                              </span>
                            ))}
                        </span>
                        <input
                          {...register("confirmPassword", {
                            onBlur: (e) => {
                              confirmPasswordShow("password");
                            },
                            required: true,
                          })}
                          type={"password"}
                          id={"confirmPassword"}
                          className={`form-control ${
                            errors.confirmPassword && "is-invalid"
                          }`}
                        />
                      </div>
                    </div>

                    {errors.confirmPassword && (
                      <p className="text-danger">
                        {errors.confirmPassword?.message}
                      </p>
                    )}
                  </div>
                  <div className="input-group full-width basic_details_form changepasswordbutns">
                    <div className="form-group BDsubmitbutton d-flex">
                      <button
                        type="button"
                        className="btn btn-warning btn-login d-block mb-5 cancelbutton ml-auto mr-2"
                        onClick={() => resetForm()}
                      >
                        <i className="fa-solid fa-xmark"></i> Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn-success btn-login d-block mb-5 changepasswordbtn"
                      >
                        <i class="fa-regular fa-circle-check"></i>
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="PasswordIntruction">
                <h4>Password must use:</h4>
                <ul className="list-group">
                  <li className={`list-group-item bg-light d-flex`}>
                    {eightState ? (
                      <span className="check"></span>
                    ) : (
                      <div>
                        <i className="fa fa-minus-circle circleIcon" />
                      </div>
                    )}{" "}
                    {MSG.EIGHTCHAR}
                  </li>

                  <li className={`list-group-item bg-light d-flex`}>
                    {upperCaseComplexity && lowerCaseComplexity ? (
                      <span className="check"></span>
                    ) : (
                      <div>
                        <i className="fa fa-minus-circle circleIcon" />
                      </div>
                    )}
                    {MSG.UPPRLWRCHAR}
                  </li>

                  <li className={`list-group-item bg-light d-flex`}>
                    {numberComplexity ? (
                      <span className="check"></span>
                    ) : (
                      <div>
                        <i className="fa fa-minus-circle circleIcon" />
                      </div>
                    )}
                    {MSG.ONENUM}
                  </li>

                  <li className={`list-group-item bg-light d-flex`}>
                    {specialCharState ? (
                      <span className="check"></span>
                    ) : (
                      <div>
                        <i className="fa fa-minus-circle circleIcon" />
                      </div>
                    )}
                    {MSG.SPCLCHAR}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default ChangePassword;
