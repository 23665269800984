/** @format */

export const MSG = {
  FNAMEREQ: "Field is required",
  LNAMEREQ: "Field is required",
  NAMEREQ: "Field is required",
  DOBREQ: "Field is required",
  DATEREQ: "Field is required",
  ALPHAREQ: "Field is required",
  EMAILREQ: "Field is required",
  INVEMAILREQ: "Field is required",
  UNAMEREQ: "Field is required",
  PASSREQ: "Field is required",
  CPASSREQ: "Field is required",
  NEWPASSREQ: "Field is required",
  OLDPASSREQ: "Field is required",
  ZCODE: "Field is required",
  INZCODE: "Invalid Zipcode.",
  ADDR1REQ: "Field is required",
  CITYREQ: "Field is required",
  MAX100CHREQ: "Maximum 100 charaters are allowed.",
  URLREQ: "URL is required.",
  VLINKREQ: "Please enter atleast one video link.",
  ACTNAMEREQ: "Activity Name is required.",
  MAX255CHREQ: "Maximum 255 charaters are allowed.",
  VALIDINFOREQ: "Insert valid inforamtion.",
  MAX15CHREQ: "Maximum 15 characters.",
  MOBNUMREQ: "Mobile number is required.",
  INVMOBNUMREQ: "Invalid Mobile number.",
  SCORREQ: "Score is required.",
  INTRESTREQ: "Interest is required.",
  COURSEREQ: "Course is required.",
  VALIDNAMREQ: "Please Enter Valid Name.",
  LINKREQ: "Link is required.",
  NUMREQ: "Only numbers allowed.",
  SKILL:
    "You Need to remove first from selected elective skill to add new skill.",
  GRADEREQ: "Grade is required",
  STATEREQ: "State is required.",
  COUNTRYREQ: "Country is required.",
  RESOURCETAB: "Resource Tab",
  COURSETAB: "Course Counter",
  HELPTAB: "Lesson Complete information?",
  COMPLETEDTAB: "Lesson Complete information?",
  SCHOOLNMREQ: "School Name is required.",
  SUBREQ: "Subject is required.",
  KEYAREAEXP:
    " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it",
  STRNGPWD: "Not a strong password (e.g. Password@123)",
  ONENUM: "At least one number.",
  EIGHTCHAR: "Password must contain at least 8 characters.",
  UPPRLWRCHAR: "At least one uppercase and one lowercase character.",
  SPCLCHAR: "At least one special character like “& . $ @”.",
  PWDNOTMTCH: "Password does not match.",
};
