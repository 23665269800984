/** @format */

import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { PATHS } from "./index";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { isAuth } = useSelector((state) => state.collections);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? <Component {...props} /> : <Redirect to={PATHS.LANDINGPAGE} />
      }
    />
  );
};

export default PrivateRoute;
