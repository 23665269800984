/** @format */

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Sidebar, SecureTopbar } from "./base";

import { isLoading, selectedUser, resetResponse } from "../redux/actions";
import { swal } from "../utils/Packages";

import { getUserDetails } from "../utils/helper";
import { useSelector, useDispatch } from "react-redux";

const Home = ({ children }, props) => {
  const path = useParams();

  const dispatch = useDispatch();
  const { loading, alluserdetails, error, response } = useSelector(
    (state) => state.collections
  );

  useEffect(() => {
    if (error) {
      if (error === "Network Error") {
        // new swal({
        //   position: "top-center",
        //   icon: "error",
        //   title: "Error",
        //   timer: 2000,
        //   text: error,
        //   type: "error",
        // });
        // dispatch(logoutAction());
      } else if (error === "Unauthorized Access") {
        dispatch(resetResponse()).then(() => {
          dispatch(isLoading(false)).then(() => {
            // window.location.assign(PATHS.ACCESS_DENIED);
          });
        });
      } else {
        // new swal({
        //   position: "top-center",
        //   icon: "error",
        //   title: "Error",
        //   timer: 2000,
        //   text: error,
        //   type: "error",
        // });
        dispatch(resetResponse());
        dispatch(isLoading(false));
      }
    }
  }, [error]);

  useEffect(() => {
    if (response && response?.success) {
      new swal({
        position: "top-center",
        icon: "success",
        title: "Success",
        timer: 2000,
        text: response?.message,
        type: "success",
      });
      dispatch(resetResponse());
      window.scrollTo(0, 0);
    }
  }, [response]);

  useEffect(() => {
    dispatch(selectedUser(getUserDetails(alluserdetails, path))).then(() => {
      if (loading) {
        setTimeout(() => {
          dispatch(isLoading(false));
        }, 1000);
      }
    });
  }, [loading, path]);

  return (
    <div>
      <SecureTopbar />
      <Sidebar />
      <div id="content">
        <main id="main" class="inner-page-h">
          {children && children}
        </main>
      </div>
    </div>
  );
};

export default Home;
