/** @format */

import {
  FREE_SIGNUP,
  USER_LOGIN,
  LOGOUT,
  USER_LOGIN_FAILURE,
  GET_ALL_USERS_DETAIL_SUCCESS,
  BASIC_DETAIL_SUCCESS,
  BASIC_DETAIL_FAILURE,
  CHILD_DETAIL_SUCCESS,
  CHILD_DETAIL_FAILURE,
  GET_EDUCATION_DETAIL_SUCCESS,
  GET_DEGREES_SUCCESS,
  GET_CMS,
  GET_SCHOOLS_SUCCESS,
  GET_SCHOOLS_KEYWORDS_SUCCESS,
  USER_EXPERIENCE_SUCCESS,
  USER_EXPERIENCE_FAILURE,
  GET_INDUSTRIES_SUCCESS,
  GET_USER_EXPERIENCE_SUCCESS,
  GET_USER_EXPERIENCE_FAILURE,
  USER_EDUCATION,
  SELF_SIGNUP_SUCCESS,
  SELF_SIGNUP_FAILURE,
  BASIC_DETAIL_ICON,
  SIDEBAR,
  ISLOADING,
  RESPONSE,
  RESPONSE_FAILURE,
  GET_EDUCATION,
  GET_COURSE,
  GET_ENRICH_KEYWORDS_SUCCESS,
  AUTH_TRUE,
  LOGGEDIN_USER,
  GET_INTEREST_SUCCESS,
  CHILD_LOGIN,
  PARENT_LOGIN,
  GET_INTERESTBY_ID_SUCCESS,
  GET_DIMENSIONS_SUCCESS,
  UPLOAD_RESPONSE,
  ADD_INTEREST_RESPONSE,
  RESPONSE_OK,
  GET_ACTIVITY,
  FORGOT_PASSWORD,
  ACTIVITY_RATING,
  GET_SKILLS,
  GET_SERVICES,
  IMAGEDOWNLOAD,
  ADD_SKILLS,
  GET_POPULAR_TITLES,
  ADD_TITLE,
  UPLOAD_VIDEO,
  ADD_PROVIDER_DETAIL,
  USER_SIGNUP,
  GET_ASSDATA,
  GET_PROVIDER,
  TECHERASSESSSUBMIT,
  GET_CURRICULAM,
  VERIFY_USER,
  RESPONSE_ERROR,
  DASHBOARD,
  HELPMODAL,
  SHOWFORM,
} from "../actions/Types";

const initialState = {
  isAuth: localStorage.getItem("access_token") ? true : false,
  loading: false,
  error: null,
  jwt: localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
    : null,
  sidebar: false,
  notification: false,
  dimension: [],
};

// changed to arrow function
const collections = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        signinresponse: action.payload,
        loading: true,
        isAuth: true,
        jwt: action.payload.jwt,
      };
    case USER_SIGNUP:
      return {
        ...state,
        signupresponse: action.payload,
        loading: false,
        isAuth: true,
        jwt: action.payload.jwt,
      };
    case SIDEBAR:
      return { ...state, sidebar: action.payload, isAuth: true };
    case ISLOADING:
      return { ...state, loading: action.payload, isAuth: state.isAuth };
    case LOGOUT:
      return { user: [], users: [], isAuth: false, loading: false };
    case FREE_SIGNUP:
      return {
        ...state,
        freesignup: action.payload,
        isAuth: true,
        jwt: action.payload.jwt,
      };
    case SELF_SIGNUP_SUCCESS:
      return { ...state, selfsignupresponse: action.payload };
    case SELF_SIGNUP_FAILURE:
      return { ...state, loading: true, error: action.payload };
    case USER_LOGIN_FAILURE:
      return { ...state, error: action.payload };
    case GET_ALL_USERS_DETAIL_SUCCESS:
      return { ...state, loading: true, userDetails: action.payload };

    case BASIC_DETAIL_SUCCESS:
      return { ...state, basicdetail: action.payload };
    case BASIC_DETAIL_FAILURE:
      return { ...state, error: action.payload };
    case CHILD_DETAIL_SUCCESS:
      return { ...state, updatechilddetail: action.payload };
    case CHILD_DETAIL_FAILURE:
      return { ...state, error: action.payload };
    case BASIC_DETAIL_ICON:
      return { ...state, basicdetailsupdate: action.payload };
    case GET_EDUCATION_DETAIL_SUCCESS:
      return { ...state, geteducationdetail: action.payload, loading: false };

    case GET_SCHOOLS_SUCCESS:
      return { ...state, schooldetail: action.payload, loading: false };
    case GET_SCHOOLS_KEYWORDS_SUCCESS:
      return { ...state, schoolsbykeyword: action.payload, loading: false };

    case GET_CMS:
      return { ...state, cmsData: action.payload, loading: false };

    case GET_DEGREES_SUCCESS:
      return { ...state, degreedetail: action.payload, loading: false };

    case USER_EDUCATION:
      return { ...state, education: action.payload };
    case USER_EXPERIENCE_SUCCESS:
      return { ...state, experience: action.payload };
    case USER_EXPERIENCE_FAILURE:
      return { ...state, error: action.payload };
    case GET_INDUSTRIES_SUCCESS:
      return { ...state, allindustries: action.payload };
    case GET_USER_EXPERIENCE_SUCCESS:
      return { ...state, getuserexperience: action.payload, loading: false };
    case GET_USER_EXPERIENCE_FAILURE:
      return { ...state, error: action.payload };

    case RESPONSE:
      return { ...state, response: action.payload, loading: false };
    case AUTH_TRUE:
      return { ...state, isAuth: true };
    case RESPONSE_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case GET_EDUCATION:
      return { ...state, educationItem: action.payload, loading: true };
    case GET_COURSE:
      return { ...state, courseItem: action.payload };
    case GET_ENRICH_KEYWORDS_SUCCESS:
      return { ...state, enrichByKeywords: action.payload };
    case LOGGEDIN_USER:
      return { ...state, loggedInUser: action.payload, isAuth: true };

    case GET_INTERESTBY_ID_SUCCESS:
      return { ...state, interestById: action.payload, loading: true };
    case GET_DIMENSIONS_SUCCESS:
      return { ...state, getdimension: action.payload, loading: true };
    case UPLOAD_RESPONSE:
      return { ...state, uploadInterest: action.payload };
    case ADD_INTEREST_RESPONSE:
      return { ...state, addInt: action.payload };

    case RESPONSE_OK:
      return { ...state, response_ok: action.payload, loading: false };

    case GET_ACTIVITY:
      return { ...state, activityData: action.payload };
    case FORGOT_PASSWORD:
      return { ...state, response: action.payload };

    case ACTIVITY_RATING:
      return { ...state, overAllRating: action.payload };
    case GET_SKILLS:
      return { ...state, skills: action.payload };
    case GET_SERVICES:
      return { ...state, services: action.payload };
    case IMAGEDOWNLOAD:
      return { ...state, downloadImage: action.payload };
    case ADD_SKILLS:
      return { ...state, addSkill: action.payload };
    case GET_POPULAR_TITLES:
      return { ...state, popularTitle: action.payload };
    case ADD_TITLE:
      return { ...state, addTitle: action.payload };
    case UPLOAD_VIDEO:
      return { ...state, uploadVideo: action.payload };
    case ADD_PROVIDER_DETAIL:
      return { ...state, addProvDetail: action.payload };
    case GET_ASSDATA:
      return { ...state, getAssessData: action.payload };
    case "SEARCHPROPERTY":
      return { ...state, searchPropertyData: action.payload };
    case "PROPERTYLIST":
      return { ...state, propertyListData: action.payload };
    case "GET_NEWSDETAILS":
      return { ...state, newsDetailsData: action.payload };

    case VERIFY_USER:
      return { ...state, verifyUserData: action.payload };
    case RESPONSE_ERROR:
      return { ...state, responseError: action.payload };
    case "NEWSLIST":
      return { ...state, newsListing: action.payload };
    case "LANDINGPAGE":
      return { ...state, landingData: action.payload };
    case "DETAILPROPERTY":
      return { ...state, propertyData: action.payload };
    case "OFFER_LISTING":
      return { ...state, offerListingData: action.payload };
    case SHOWFORM:
      return { ...state, modalData: action.payload };

    default:
      return { ...state };
  }
};

export default collections;
