/** @format */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { sendEnquiry } from "../../redux/actions/APIs";
import * as image from "../../resources/images";
import WebLayout from "../layout/WebLayout";
import { MSG } from "../../utils/Messages";
import { useForm } from "../../utils/Packages";
const ContactUs = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const { response } = useSelector((state) => state.collections);

  useEffect(() => {
    if (response) {
      reset();
    }
  }, [response]);
  const _onSubmit = (data) => {
    dispatch(sendEnquiry(data));
  };
  return (
    <WebLayout>
      <section class="breadcrumbs section-bg-gray">
        <div class="container-fluid px-3 px-md-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Contact Us</h2>
            <ol>
              <li>
                <a href="#">Home</a>
              </li>
              <li>Contact Us </li>
            </ol>
          </div>
        </div>
      </section>

      <section id="" class="d-flex align-items-center py-1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.99973488602!2d75.65047209086042!3d26.885141679373124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1663949231506!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>

      <section id="about" class="about section-bg pb-5">
        <div class="container">
          <div class="section-title logo-heading">
            <h3>Connect with us</h3>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="img-overlap">
                <img src={image.property1} class="img-fluid" alt="" />
                <img
                  src="../../img/img-2.png"
                  class="img-fluid img2pos"
                  alt=""
                />
              </div>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center">
              <form
                name="addproperty"
                className="content"
                enctype="multipart/form-data"
                onSubmit={handleSubmit(_onSubmit)}
              >
                <div className="row">
                  <div className="col-md-6 pos-rel mb-3">
                    <label className="mb-2">Name</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      {...register("name", {
                        required: {
                          value: true,
                          message: MSG.FNAMEREQ,
                        },
                      })}
                    />

                    <div className="invalid-feedback">
                      {errors?.name?.message}
                    </div>
                  </div>

                  <div className="col-md-6 pos-rel mb-3">
                    <label className="mb-2">Email</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      {...register("email", {
                        required: {
                          value: true,
                          message: MSG.FNAMEREQ,
                        },
                      })}
                    />

                    <div className="invalid-feedback">
                      {errors?.email?.message}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 pos-rel mb-3">
                    <label className="mb-2">Phone</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.phone_number ? "is-invalid" : ""
                      }`}
                      {...register("phone_number", {
                        required: {
                          value: true,
                          message: MSG.FNAMEREQ,
                        },
                      })}
                    />

                    <div className="invalid-feedback">
                      {errors?.phone_number?.message}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 pos-rel mb-3">
                    <label className="mb-2">Message</label>
                    <textarea
                      type="text"
                      className={`form-control ${
                        errors.message ? "is-invalid" : ""
                      }`}
                      {...register("message", {
                        required: {
                          value: true,
                          message: MSG.FNAMEREQ,
                        },
                      })}
                    />

                    <div className="invalid-feedback">
                      {errors?.message?.message}
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <button type="submit" class="btn btn-primary ml-2">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
};

export default ContactUs;
