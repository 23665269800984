/** @format */

import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useForm, swal, Modal, Button} from "../../utils/Packages";
import {forgotPassword} from "../../redux/actions/APIs";
import {EMAIL_REGEX} from "../../utils/Regex";
import {isLoading, resetGuestResponse, showModal} from "../../redux/actions";
import {MSG} from "../../utils/Messages";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const {response, loading} = useSelector((state) => state.collections);

    const {register, handleSubmit, formState, reset} = useForm({
        mode: "onTouched",
    });
    const {errors} = formState;

    const _onSubmit = (value) => {
        dispatch(isLoading(true));
        dispatch(forgotPassword(value));
    };
    useEffect(() => {
        if (response && response?.success) {
            reset();
            new swal({
                position: "top-center",
                icon: "success",
                title: "Success",
                timer: 5000,
                text: response?.message,
                type: "success",
            });
            dispatch(isLoading(false));
            dispatch(resetGuestResponse());
        }
    }, [response]);

    return (
        <React.Fragment>
            <Modal
                show={true}
                className="SignupPopup AddChildPopup"
                onHide={() => dispatch(showModal())}
            >
                <Modal.Body className="p-0">
                    <div className="SignupRegistration basicdetailsform in-up-form">
                        <Modal.Header>
                            <div class="modal-title flex h4">
                                {/*<img src={image.Signupicon} alt="" className="mr-2" />*/}
                                <i className="fa-solid fa-id-card mr-2 fontawsomeicon"></i>
                                <h3>Forgot My Password</h3>
                            </div>
                            <Button
                                data-dismiss="modal"
                                onClick={() => dispatch(showModal())}
                            >
                                <i className="fa-solid fa-xmark m-0"></i>
                            </Button>
                        </Modal.Header>


                        <div id="signin">
                            <div className="p-3">

                                <form
                                    name="forgotpassword"
                                    className="bg-white content"
                                    onSubmit={handleSubmit(_onSubmit)}
                                >
                                    <div className="wrapper mx-auto signin_box_styl">
                                        <div className="input-group">
                                            <label>
                                                Email/Username
                                                <span className="mandatoryField">*</span>
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    placeholder="Email/Username"
                                                    className={`form-control ${
                                                        errors.username ? "is-invalid" : ""
                                                    }`}
                                                    {...register("email", {
                                                        required: {
                                                            value: true,
                                                            message: MSG.EMAILREQ,
                                                        },
                                                        pattern: {
                                                            value: EMAIL_REGEX,
                                                            message: MSG.INVEMAILREQ,
                                                        },
                                                    })}
                                                />
                                                <div className="invalid-feedback">
                                                    {errors.username?.message}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-end">
                                        <button
                                            disabled={loading ? true : false}
                                            type="button"
                                            className="black-btn btn-small"
                                            onClick={() => reset()}
                                        >
                                            <i className="fa-solid fa-xmark mr-2"></i> Reset
                                        </button>

                                        {loading ? (
                                            <button
                                                className="black-btn btn-small"
                                                key={Math.random()}
                                            >
                                                <span className="RounAnimation"></span> Please Wait...
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="black-btn btn-small"
                                            >
                                                <i className="fa-solid fa-paper-plane mr-2"></i> Send Link
                                            </button>
                                        )}
                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default ForgotPassword;
