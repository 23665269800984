/** @format */

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";

import "react-phone-input-2/lib/style.css";
import "./resources/css/Rangeslider.css";

import "font-awesome/css/font-awesome.min.css";
import "rsuite/styles/index.less";

import "./resources/css/Reset.css";

import "./resources/css/style.css";
import "./resources/css/sb-admin-2.css";

import "rsuite/dist/rsuite.css";

import App from "./App";
import reducers from "./redux/reducers";
import "./App.css";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
