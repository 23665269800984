/** @format */

import React, { useState, useEffect } from "react";

import Home from "../Home";

const InitAssessments = ({ inactive }) => {
  // useEffect(() => {
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };
  // });

  /* Method that will fix header after a specific scrollable */
  // const isSticky = (e) => {
  //   const header = document.querySelector(".SpecialLeftpanel");
  //   const scrollTop = window.scrollY;
  //   scrollTop >= 1
  //     ? header.classList.add("LPwidth")
  //     : header.classList.remove("LPwidth");
  // };

  return (
    <Home>
      <section class="breadcrumbs section-bg-gray">
        <div class="container-fluid px-3 px-md-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Dashboard</h2>
            <ol>
              <li>
                <a href="#">Home</a>
              </li>
              <li>Inner Page</li>
            </ol>
          </div>
        </div>
      </section>
      <div className="d-flex flex-wrap SpecialLeftpanel w-100">
        <div className="d-flex w-100 align-items-start overflow-visible"></div>
      </div>
    </Home>
  );
};

export default InitAssessments;
