/** @format */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Geocode from "react-geocode";

import { addProperty } from "../../redux/actions/APIs";
import { SelectPickerVal } from "../../utils/helper";
import { MSG } from "../../utils/Messages";

import Home from "../Home";

import { Country, State, City } from "country-state-city";
import {
  useForm,
  Controller,
  SelectPicker,
  useHistory,
} from "../../utils/Packages";
import { PATHS } from "../../utils";
import GoogleMapReact from "google-map-react";
const AddProperty = () => {
  Geocode.setApiKey("AIzaSyAwaEppAtCYT2HohGekfylXx4GohGLlkSY");

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onTouched" });
  const dispatch = useDispatch();
  const history = useHistory();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [lat, setLat] = useState(40.756795);
  const [long, setLong] = useState(-73.954298);

  const [images, setImages] = useState([]);

  const param = useParams();
  const { response_ok, cmsData } = useSelector((state) => state.collections);
  const { loading, validateUser } = useSelector((state) => state.collections);

  const _onSubmit = (data) => {
    const formData = new FormData();

    formData.append("floar_plan", data.floar_plan[0]);

    formData.append("image", data.image[0]);
    formData.append("address", data.address);
    formData.append("bathrooms", data.bathrooms);
    formData.append("bedrooms", data.bedrooms);
    formData.append("city", data.city);
    formData.append("contact_number", data.contact_number);
    formData.append("contact_person_name", data.contact_person_name);
    formData.append("country", data.country);
    formData.append("description", data.description);
    // formData.append("features", JSON.stringify(data.features));
    formData.append("garage", data.garage);
    formData.append("garage_size", data.garage_size);
    formData.append("land_area", data.land_area);
    formData.append("lat", data.lat);
    formData.append("longtitue", data.longtitue);
    formData.append("other_address", data.other_address);
    formData.append("other_contact", data.other_contact);
    formData.append("price", data.price);
    formData.append("square_footage", data.square_footage);
    formData.append("state", data.state);
    formData.append("title", data.title);
    formData.append("type", data.type);
    formData.append("video_url", data.video_url);
    formData.append("year_built", data.year_built);
    formData.append("zip_code", data.zip_code);

    dispatch(addProperty(formData));
  };
  useEffect(() => {
    if (response_ok) {
      dispatch(addProperty());
      history.push(PATHS.PROPERTYLIST);
    }
  }, [response_ok]);
  const [selectedCountry, setSelectedCountry] = useState();
  const changeCountry = (id) => {
    setSelectedCountry(id);
    let state = State.getAllStates().filter((data) => data?.countryCode === id);
    let res = Country.getAllCountries().filter((data) => data.isoCode === id);

    // if (res[0]?.latitude && res[0]?.longitude) {
    //   console.log("step2", res[0]);
    //   setValue("lat", res[0]?.latitude);
    //   setValue("longtitue", res[0]?.longitude);
    //   setLat(res[0]?.latitude);
    //   setLong(res[0]?.longitude);
    // }

    setStates(state);
  };
  const changeState = (id) => {
    let citiess = City.getAllCities().filter(
      (data) => data?.stateCode === id && data?.countryCode === selectedCountry
    );
    setCities(citiess);
  };
  const changeCity = (e) => {
    let res = cities.filter((data) => data.name === e);
    // if (res[0]?.latitude && res[0]?.longitude) {
    //   setValue("lat", res[0]?.latitude);
    //   setValue("longtitue", res[0]?.longitude);
    //   setLat(res[0]?.latitude);
    //   setLong(res[0]?.longitude);
    // }
  };

  const loadMap = (map, maps) => {
    let abc = new maps.Marker({
      position: { lat: lat, lng: long },
      map,
      draggable: true,
    });
    console.log(abc, "thari");
  };
  const change = (e) => {
    setValue("lat", e.lat);
    setValue("longtitue", e.lng);
    Geocode.fromLatLng(e.lat, e.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        let city, state, country, postal_code;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].short_name;
                break;
              case "postal_code":
                postal_code =
                  response.results[0].address_components[i].short_name;
                break;
            }
          }
        }
        console.log(postal_code, "kkkk");
        setValue("country", country);
        changeCountry(country);
        let stateData = State.getAllStates().filter(
          (data) => data?.countryCode == country && data?.name == state
        );
        setValue("state", stateData[0]?.isoCode);
        setValue("zip_code", postal_code);
        changeState(stateData[0]?.isoCode);
        changeCity(city);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const floorUploadHandler = async (e) => {
    let name = e.target.files[0].name.split(".");
    if (e.target.files[0] && e.target.files[0].size > Number(10000000)) {
      console.log(e);
      e.target.value = "";
      return;
    }

    if (
      e.target.files[0] &&
      !(name[1] === "pdf" || name[1] === "jpeg" || name[1] === "png")
    ) {
      console.log(e);
      e.target.value = "";
      return;
    }

    const file = e.target.files[0];

    console.log(e.target.files);

    e.target.value = "";
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onImageChange = (e) => {
    setImages([...e.target.files]);
    console.log(...e.target.files, "bc kya h ye");
  };

  const handleSliderImages = (e) => {
    console.log(e, "bc");
    if (e.target.files) {
      setImages({ ...images, ...e.target.files });

      //  setProducts({ ...products, slider_images: [...e.target.files] });
      console.log("Update slider images", images);
    }
  };

  return (
    <Home>
      <section className="breadcrumbs section-bg-gray">
        <div className="container-fluid px-3 px-md-4">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Property Listing</h2>
            <ol>
              <li>
                <a href="#">Home</a>
              </li>
              <li>Property Listing</li>
            </ol>
          </div>
        </div>
      </section>

      <section className="inner-page-content">
        <div className="container">
          <div className="row justify-content-center">
            <form
              name="addproperty"
              className="content"
              onSubmit={handleSubmit(_onSubmit)}
            >
              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">Title</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.title ? "is-invalid" : ""
                    }`}
                    {...register("title", {
                      required: {
                        value: true,
                        message: MSG.FNAMEREQ,
                      },
                    })}
                  />

                  <div className="invalid-feedback">
                    {errors?.title?.message}
                  </div>
                </div>
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">Description</label>
                  <input
                    type="text"
                    // placeholder="Last Name"
                    className={`form-control ${
                      errors.description ? "is-invalid" : ""
                    }`}
                    {...register("description", {
                      required: {
                        value: true,
                        message: MSG.LNAMEREQ,
                      },
                    })}
                  />
                  <div className="invalid-feedback">
                    {errors?.description?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">Country</label>
                  <Controller
                    {...register("country", {
                      required: true,
                    })}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      onChange = (event) => {
                        changeCountry(event);
                        console.log(event);
                        setValue("country", event);
                        clearErrors("country");
                      };

                      return (
                        <SelectPicker
                          onChange={onChange}
                          className={`form-control ${
                            errors.country ? "is-invalid" : ""
                          }`}
                          key={getValues("country")}
                          defaultValue={value ? value : getValues("country")}
                          data={SelectPickerVal(
                            Country.getAllCountries(),
                            "country"
                          )}
                          searchable={true}
                          cleanable={false}
                        />
                      );
                    }}
                  />
                  <div className="invalid-feedback">
                    {errors?.country?.message}
                  </div>
                </div>
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">State</label>
                  <Controller
                    {...register("state", {
                      required: true,
                    })}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      onChange = (event) => {
                        changeState(event);

                        setValue("state", event);
                        clearErrors("state");
                      };
                      return (
                        <SelectPicker
                          onChange={onChange}
                          className={`form-control ${
                            errors.state ? "is-invalid" : ""
                          }`}
                          key={getValues("state")}
                          defaultValue={value ? value : getValues("state")}
                          data={SelectPickerVal(states, "country")}
                          searchable={true}
                          cleanable={false}
                        />
                      );
                    }}
                  />
                  {errors.state && (
                    <p className="invalid-feedback">{MSG.STATEREQ}</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">City</label>
                  <Controller
                    {...register("city", {
                      required: true,
                    })}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      onChange = (event) => {
                        setValue("city", event);
                        changeCity(event);
                        clearErrors("city");
                      };
                      return (
                        <SelectPicker
                          onChange={onChange}
                          key={getValues("city")}
                          defaultValue={value ? value : getValues("city")}
                          className={`form-control ${
                            errors.city ? "is-invalid" : ""
                          }`}
                          data={SelectPickerVal(cities, "city")}
                          searchable={true}
                          cleanable={false}
                        />
                      );
                    }}
                  />
                  {errors.state && (
                    <p className="invalid-feedback">{MSG.STATEREQ}</p>
                  )}
                </div>

                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Zipcode<span className="mandatoryField">*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control ${
                      errors.zip_code || validateUser?.message
                        ? "is-invalid"
                        : ""
                    }`}
                    {...register("zip_code", {
                      required: {
                        value: true,
                        message: MSG.EMAILREQ,
                      },
                    })}
                  />
                  <div className="invalid-feedback">
                    {errors?.zip_code?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  {" "}
                  <div style={{ height: "200px", width: "100%" }}>
                    <GoogleMapReact
                      apiKey="AIzaSyAwaEppAtCYT2HohGekfylXx4GohGLlkSY"
                      defaultZoom={5}
                      draggable={true}
                      defaultCenter={{ lat: lat, lng: long }}
                      yesIWantToUseGoogleMapApiInternals
                      onClick={change}
                      onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    lattitude<span className="mandatoryField">*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    className={`form-control ${errors.lat ? "is-invalid" : ""}`}
                    {...register("lat", {
                      required: {
                        value: true,
                        message: MSG.EMAILREQ,
                      },
                    })}
                  />
                </div>
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Longtitue.<span className="mandatoryField">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.longtitue ? "is-invalid" : ""
                    }`}
                    disabled
                    {...register("longtitue", {
                      required: {
                        value: true,
                        message: MSG.EMAILREQ,
                      },
                    })}
                  />
                  <div className="invalid-feedback">
                    {errors?.longtitue?.message}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Square/Ft<span className="mandatoryField">*</span>
                  </label>

                  <input
                    className={`form-control ${
                      errors.square_footage ? "is-invalid" : ""
                    }`}
                    {...register("square_footage", {
                      required: {
                        value: true,
                        message: MSG.PASSREQ,
                      },
                    })}
                    type={"text"}
                    id={"square_footage"}
                  />
                  <div className="invalid-feedback">
                    {errors?.square_footage?.message}
                  </div>
                </div>
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Landarea<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.land_area ? "is-invalid" : ""
                    }`}
                    {...register("land_area", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.land_area?.message}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Type<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.confirmpassword ? "is-invalid" : ""
                    }`}
                    {...register("type", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.type?.message}
                  </div>
                </div>
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Price<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.price ? "is-invalid" : ""
                    }`}
                    {...register("price", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.price?.message}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Bedrooms<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.bedrooms ? "is-invalid" : ""
                    }`}
                    {...register("bedrooms", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.bedrooms?.message}
                  </div>
                </div>

                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Bathrooms<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.bathrooms ? "is-invalid" : ""
                    }`}
                    {...register("bathrooms", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.bathrooms?.message}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Garage<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.garage ? "is-invalid" : ""
                    }`}
                    {...register("garage", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.garage?.message}
                  </div>
                </div>
                <div className="col-md-6 pos-rel mb-3">
                  <div className="form-group">
                    <label className="mb-2">
                      Garage Size<span className="mandatoryField">*</span>
                    </label>
                    <input
                      className={`form-control ${
                        errors.garage_size ? "is-invalid" : ""
                      }`}
                      {...register("garage_size", {
                        required: {
                          value: true,
                          message: MSG.CPASSREQ,
                        },
                      })}
                      type={`text`}
                      id={"type"}
                    />
                    <div className="invalid-feedback">
                      {errors?.garage_size?.message}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Year Built<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.year_built ? "is-invalid" : ""
                    }`}
                    {...register("year_built", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.year_built?.message}
                  </div>
                </div>
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Address<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.address ? "is-invalid" : ""
                    }`}
                    {...register("address", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.address?.message}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Video Url<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.video_url ? "is-invalid" : ""
                    }`}
                    {...register("video_url", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.video_url?.message}
                  </div>
                </div>

                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Upload Floor Plan<span className="mandatoryField">*</span>
                  </label>
                  <div>
                    <label
                      htmlFor="custom-button"
                      className="btn font-weight-medium mb15 text-center btn-warning small-btn text-white mobileProfileBtn"
                    >
                      Upload Floor Plan
                    </label>
                    <input
                      type="file"
                      id="custom-button"
                      hidden
                      {...register("floar_plan")}
                    />
                  </div>

                  <p className="sub-h9 font-weight-light mobilefont-20">
                    Must be Pdf (max 10mb)
                  </p>
                </div>

                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Upload Property Image
                    <span className="mandatoryField">*</span>
                  </label>
                  <div>
                    <label
                      htmlFor="custom-button1"
                      className="btn font-weight-medium mb15 text-center btn-warning small-btn text-white mobileProfileBtn"
                    >
                      Upload Images
                    </label>
                    <input
                      type="file"
                      id="custom-button1"
                      hidden
                      accept="image/*"
                      {...register("image")}
                    />
                  </div>

                  <p className="sub-h9 font-weight-light mobilefont-20">
                    Must be JPG, PNG (max 10mb)
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Other Contact<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.other_contact ? "is-invalid" : ""
                    }`}
                    {...register("other_contact", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.other_contact?.message}
                  </div>
                </div>
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Other Address<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.other_address ? "is-invalid" : ""
                    }`}
                    {...register("other_address", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.other_address?.message}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Contact Number<span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.contact_number ? "is-invalid" : ""
                    }`}
                    {...register("contact_number", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.contact_number?.message}
                  </div>
                </div>
                <div className="col-md-6 pos-rel mb-3">
                  <label className="mb-2">
                    Contact Person Name
                    <span className="mandatoryField">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.contact_person_name ? "is-invalid" : ""
                    }`}
                    {...register("contact_person_name", {
                      required: {
                        value: true,
                        message: MSG.CPASSREQ,
                      },
                    })}
                    type={`text`}
                    id={"type"}
                  />
                  <div className="invalid-feedback">
                    {errors?.contact_person_name?.message}
                  </div>
                </div>
              </div>
              {/*<div className="row">*/}
              {/*  <div className="col-md-12 pos-rel mb-3">*/}
              {/*    <div className="feature-list">*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="air_cooling"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}
              {/*        <label htmlFor="">Air Cooling</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="heating"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}
              {/*        <label htmlFor="">Electric Range</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="electric_range"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Fire Alarm</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="fire_alarm"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Gym</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="gym"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Home Theater</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="home_theater"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Laundry</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="laundry"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Laundry Room</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="laundry_room"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Marble Flooring</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="marble_flooring"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Microwave</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="microwave"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}
              {/*        <label htmlFor="">Refrigerator</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="refrigerator"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Sauna</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="sauna"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Swimming Pool</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="swimming_pool"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Tv Cable</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="tv_cable"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Washer</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="washer"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}

              {/*        <label htmlFor="">Wifi</label>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          value="wifi"*/}
              {/*          {...register("features")}*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="row">
                <div className="col mt-2">
                  {loading ? (
                    <button className="black-btn btn-small" key={Math.random()}>
                      <span className="RounAnimation"></span> Please Wait...
                    </button>
                  ) : (
                    <button
                      key={Math.random()}
                      type="submit"
                      className="black-btn btn-small"
                    >
                      <i className="fa-solid fa-paper-plane"></i> Add Property
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Home>
  );
};

export default AddProperty;
