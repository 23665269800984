/** @format */

export const FREE_SIGNUP = "FREE_SIGNUP";
export const SELF_SIGNUP_SUCCESS = "SELF_SIGNUP_SUCCESS";
export const SELF_SIGNUP_FAILURE = "SELF_SIGNUP_SUCCESS_FAILURE";
export const USER_LOGIN = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const GET_ALL_USERS_DETAIL_SUCCESS = "GET_ALL_USERS_DETAIL_SUCCESS";
export const BASIC_DETAIL_SUCCESS = "BASIC_DETAIL_SUCCESS";
export const BASIC_DETAIL_FAILURE = "BASIC_DETAIL_FAILURE";
export const BASIC_DETAIL_ICON = "BASIC_DETAIL_ICON";
export const CHILD_DETAIL_SUCCESS = "CHILD_DETAIL_SUCCESS";
export const CHILD_DETAIL_FAILURE = "CHILD_DETAIL_FAILURE";
export const GET_EDUCATION_DETAIL_SUCCESS = "GET_EDUCATION_DETAIL_SUCCESS";
export const GET_DEGREES_SUCCESS = "GET_DEGREES_SUCCESS";
export const GET_FIELDS_SUCCESS = "GET_FIELDS_SUCCESS";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_KEYWORDS_SUCCESS = "GET_SCHOOLS_KEYWORDS_SUCCESS";
export const GET_USER_EXPERIENCE_SUCCESS = "GET_USER_EXPERIENCE_SUCCESS";
export const GET_USER_EXPERIENCE_FAILURE = "GET_USER_EXPERIENCE_FAILURE";
export const GET_INDUSTRIES_SUCCESS = "GET_INDUSTRIES_SUCCESS";
export const USER_EXPERIENCE_SUCCESS = "USER_EXPERIENCE_SUCCESS";
export const USER_EXPERIENCE_FAILURE = "USER_EXPERIENCE_FAILURE";
export const USER_EDUCATION = "USER_EDUCATION";
export const USER_EXPERIENCE = "USER_EXPERIENCE";
export const ADD_SCHOOL = "ADD_SCHOOL";
export const ADD_TEACHERS = "ADD_TEACHERS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SIDEBAR = "SIDEBAR";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const ISLOADING = "ISLOADING";
export const GET_GRADE_LEVEL = "GET_GRADE_LEVEL";
export const ADD_CHILD_SUCCESS = "ADD_CHILD_SUCCESS";
export const STUSCORE = "STUSCORE";
export const UPDATE_INIT_ASS_RESPONSE = "UPDATE_INIT_ASS_RESPONSE";
export const SELECTED_USER = "SELECTED_USER";
export const GET_COMPANY_BYKEYWORD = "GET_COMPANY_BYKEYWORD";
export const RESPONSE = "RESPONSE";
export const RESPONSE_FAILURE = "RESPONSE_FAILURE";
export const GET_EDUCATION = "GET_EDUCATION";
export const GET_COURSE = "GET_COURSE";
export const AUTH_TRUE = "AUTH_TRUE";
export const GET_ENRICH_KEYWORDS_SUCCESS = "GET_ENRICH_KEYWORDS_SUCCESS";
export const LOGGEDIN_USER = "LOGGEDIN_USER";

export const GET_INTERESTBY_ID_SUCCESS = "GET_INTERESTBY_ID_SUCCESS";
export const GET_DIMENSIONS_SUCCESS = "GET_DIMENSIONS_SUCCESS";
export const UPLOAD_RESPONSE = "UPLOAD_RESPONSE";
export const ADD_INTEREST_RESPONSE = "ADD_INTEREST_RESPONSE";
export const RESPONSE_OK = "RESPONSE_OK";
export const DIMENTOIN_DATA = "DIMENTOIN_DATA";
export const COURSELIST = "COURSELIST";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const GET_TITLE_BYKEYWORD = "GET_TITLE_BYKEYWORD";
export const GET_INDUSTRY_BYKEYWORD = "GET_INDUSTRY_BYKEYWORD";
export const GET_DIVISION_BYKEYWORD = "GET_DIVISION_BYKEYWORD";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_COURSEDATA = "GET_COURSEDATA";
export const META_RESPONSE = "META_RESPONSE";
export const RATINGS = "RATINGS";
export const RESOURCES_RATINGS = "RESOURCES_RATINGS";
export const ACTIVITY_RATING = "ACTIVITY_RATING";
export const GET_SKILLS = "GET_SKILLS";
export const GET_SERVICES = "GET_SERVICES";
export const IMAGEDOWNLOAD = "IMAGEDOWNLOAD";
export const GET_TEACHER = "GET_TEACHER";
export const ADD_SKILLS = "ADD_SKILLS";
export const GET_POPULAR_TITLES = "GET_POPULAR_TITLES";
export const ADD_TITLE = "ADD_TITLE";
export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const ADD_PROVIDER_DETAIL = "ADD_PROVIDER_DETAIL";
export const USER_SIGNUP = "USER_SIGNUP";
export const GET_ASSDATA = "GET_ASSDATA";
export const GET_PROVIDER = "GET_PROVIDER";
export const TECHERASSESSSUBMIT = "TECHERASSESSSUBMIT";
export const GET_CURRICULAM = "GET_CURRICULAM";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_STATE = "GET_STATE";
export const PRIMARY_SKILLS = "PRIMARY_SKILLS";
export const ENROLL_RESPONSE = "ENROLL_RESPONSE";
export const CHECK_ASSESSMENT = "CHECK_ASSESSMENT";
export const GET_METADATA = "GET_METADATA";
export const GET_RESOURCES = "GET_RESOURCES";
export const GET_USER_ACTIVITY = "GET_USER_ACTIVITY";
export const VERIFY_USER = "VERIFY_USER";
export const RESPONSE_ERROR = "RESPONSE_ERROR";
export const GET_ENROLLED_LEARNERS = "GET_ENROLLED_LEARNERS";
export const GET_ASSESS_OF_LEARNERS = "GET_ASSESS_OF_LEARNERS";
export const GET_PROGRESS_CHART = "GET_PROGRESS_CHART";
export const GET_INTEREST_CATEGORIES = "GET_INTEREST_CATEGORIES";
export const GET_INTEREST_CATEGORY_BY_ID = "GET_INTEREST_CATEGORY_BY_ID";
export const GET_INTEREST_LEVEL_BY_ID = "GET_INTEREST_LEVEL_BY_ID";
export const GET_ALL_INDUSTRIES = "GET_ALL_INDUSTRIES";

export const GET_TITLES_BYCHARACTER = "GET_TITLES_BYCHARACTER";
export const COURSE_FILLTER_RESPONSE = "COURSE_FILLTER_RESPONSE";
export const GET_COMPLEX = "GET_COMPLEX";
export const GET_LESSON = "GET_LESSON";
export const TURN_NOTIFICATION = "TURN_NOTIFICATION";
export const RESET_SONG = "RESET_SONG";
export const GET_RESOURCESDATA = "GET_RESOURCESDATA";
export const GET_SOCIAL_ACTIVITY_DATA = "GET_SOCIAL_ACTIVITY_DATA";
export const GET_SERIES_SCENES_DATA = "GET_SERIES_SCENES_DATA";
export const ADD_LEARNER = "ADD_LEARNER";
export const KEYAREA = "KEYAREA";
export const SELECTED_DIMTAB = "SELECTED_DIMTAB";
export const KEYAREA_INTELLECTUAL = "KEYAREA_INTELLECTUAL";
export const UPDATE_DIMENSION = "UPDATE_DIMENSION";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const BILLING_PLAN = "BILLING_PLAN";
export const GET_ALPHABET_DATA = "GET_ALPHABET_DATA";
export const GET_PROVIDER_COURSES = "GET_PROVIDER_COURSES";
export const BREADCRUMB = "BREADCRUMB";
export const DASHBOARD = "DASHBOARD";
export const HELPMODAL = "HELPMODAL";
export const SHOWFORM = "SHOWFORM";
export const GET_USER_NAME_RESPONSE = "GET_USER_NAME_RESPONSE";
export const KNOWLADGE_CHECK = "KNOWLADGE_CHECK";
export const NEXT_SCENE_RESPONSE = "NEXT_SCENE_RESPONSE";
export const GET_CMS = "GET_CMS";
export const PROPERTYLIST = "PROPERTYLIST";
export const GET_NEWSDETAILS = "GET_NEWSDETAILS";
export const NEWSLIST = "NEWSLIST";
export const SEARCHPROPERTY = "SEARCHPROPERTY";
export const DETAILPROPERTY = "DETAILPROPERTY";
