/** @format */

import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {useForm, swal, yupResolver, Yup} from "../../utils/Packages";
import {PATHS} from "../../utils";
import {isLoading} from "../../redux/actions";

import {useParams} from "react-router-dom";
import {resetPassword} from "../../redux/actions/APIs";
import {resetResponse} from "../../redux/actions";
import SecureTopbar from "./SecureTopbar";
import {Heading} from "../profile";
import * as image from "../../resources/images";

import {
    SPECIAL_CHAR,
    GET_LOWER_CASE,
    GET_UPPER_CASE,
    GET_NUMBER,
} from "../../utils/Regex";
import {MSG} from "../../utils/Messages";
import Topbar from "../layout/Topbar";

const ResetPassword = () => {
    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required(MSG.NEWPASSREQ)
            .matches(RegExp("(.*[a-z].*)"), MSG.STRNGPWD)
            .matches(RegExp("(.*[A-Z].*)"), MSG.STRNGPWD)
            .matches(RegExp("(.*\\d.*)"), MSG.STRNGPWD)
            .matches(RegExp('[!@#$%^&*(),.?":{}|<>_]'), "Special")
            .min(8, "Password Length must be 8 characters or more."),
        confirmPassword: Yup.string()
            .required(MSG.CPASSREQ)
            .oneOf([Yup.ref("newPassword")], "Passwords does not match."),
    });
    const {register, handleSubmit, reset, getValues, formState} = useForm({
        mode: "onTouched",
        resolver: yupResolver(validationSchema),
    });
    const {errors} = formState;
    const {response, error} = useSelector((state) => state.collections);
    const [type, setType] = useState("password");
    const [confirmType, setConfirmType] = useState("password");
    const [random, setRandom] = useState();

    const dispatch = useDispatch();
    const params = useParams();
    const [eightState, getEightState] = useState(false);
    const [specialCharState, getSpecialCharState] = useState(false);
    const [upperCaseComplexity, getUpperCaseComplexity] = useState(false);
    const [lowerCaseComplexity, getLowerCaseComplexity] = useState(false);
    const [numberComplexity, getNumberComplexity] = useState(false);

    const _onSubmit = (values) => {
        if (getValues("newPassword") !== getValues("confirmPassword")) {
            return false;
        }

        dispatch(resetPassword(values?.newPassword, params.token));
    };

    const toggleShow = () => {
        setType(type === "text" ? "password" : "text");
    };

    const confirmPasswordShow = () => {
        setConfirmType(confirmType === "text" ? "password" : "text");
    };

    const typePassword = (flag) => {
        if (flag === "New Password") {
            let item = document.getElementById("newPassword").value;
            if (item.length >= 8) {
                getEightState(true);
            } else {
                getEightState(false);
            }
            if (SPECIAL_CHAR.test(item)) {
                getSpecialCharState(true);
            } else {
                getSpecialCharState(false);
            }

            if (GET_LOWER_CASE.test(item)) {
                getLowerCaseComplexity(true);
            } else {
                getLowerCaseComplexity(false);
            }

            if (GET_UPPER_CASE.test(item)) {
                getUpperCaseComplexity(true);
            } else {
                getUpperCaseComplexity(false);
            }
            if (GET_NUMBER.test(item)) {
                getNumberComplexity(true);
            } else {
                getNumberComplexity(false);
            }
        }
    };

    // const checkPassword = () => {
    //   setRandom(Math.random());
    // };

    useEffect(() => {
        if (response && response?.success) {
            reset();
            new swal({
                position: "top-center",
                icon: "success",
                title: "Success",
                timer: 2000,
                text: response?.message,
                type: "success",
            });
        }
    }, [response]);
    useEffect(() => {
        if (error) {
            if (error === "Network Error") {
                new swal({
                    position: "top-center",
                    icon: "error",
                    title: "Error",
                    timer: 2000,
                    text: error,
                    type: "error",
                });
            } else if (error === "Unauthorized Access") {
                dispatch(resetResponse()).then(() => {
                    dispatch(isLoading(false)).then(() => {
                        window.location.assign(PATHS.ACCESS_DENIED);
                    });
                });
            } else {
                new swal({
                    position: "top-center",
                    icon: "error",
                    title: "Error",
                    timer: 2000,
                    text: error,
                    type: "error",
                });
                dispatch(resetResponse());
                dispatch(isLoading(false));
            }
        }
    }, [error]);

    const resetForm = () => {
        getEightState(false);
        getSpecialCharState(false);
        getUpperCaseComplexity(false);
        getLowerCaseComplexity(false);
        getNumberComplexity(false);
        reset();
    };
    return (
        <div>
            <Topbar/>
            <section className={'inner-page-content'}>
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className="Main">
                            <div className="MainContent ">
                                <div className="LeftbarPannel m-auto p-0 resetPassword">


                                    <div className="PannelContent basicdetailsform px-5">
                                        <div className="wrapper changepasword">
                                            <div className="Accountrelated">
                                                <h2>Change password</h2>
                                                <div className={'reset-password-description'}>
                                                    <div className="PasswordIntruction">
                                                        <div className={'reset-password-instruction'}>In order to <b>protect
                                                            your account,</b> make sure your password:
                                                        </div>
                                                        <ul>
                                                            <li>Is longer than 7 characters</li>
                                                            <li>Does not match or significantly contains your username,
                                                                e.g. do not use 'username123'.
                                                            </li>
                                                            <li>Is not a member of this <a target={'_blank'}
                                                                                           href={'https://en.wikipedia.org/wiki/List_of_the_most_common_passwords'}>list
                                                                of common passwords</a></li>
                                                            <li>Is not a member of the <a target={'_blank'}
                                                                                          href={'https://haveibeenpwned.com/'}>Have
                                                                I Been Pwned</a> breach database
                                                            </li>
                                                        </ul>
                                                        {/*<ul className="list-group">*/}
                                                        {/*    <li*/}
                                                        {/*        className={`list-group-item  ${*/}
                                                        {/*            eightState ? "text-success" : ""*/}
                                                        {/*        }`}*/}
                                                        {/*    >*/}
                                                        {/*        {eightState ? <span className="check"></span> : ""}{" "}*/}
                                                        {/*        {MSG.EIGHTCHAR}*/}
                                                        {/*    </li>*/}

                                                        {/*    <li*/}
                                                        {/*        className={`list-group-item  ${*/}
                                                        {/*            upperCaseComplexity && lowerCaseComplexity*/}
                                                        {/*                ? "text-success"*/}
                                                        {/*                : ""*/}
                                                        {/*        }`}*/}
                                                        {/*    >*/}
                                                        {/*        {upperCaseComplexity && lowerCaseComplexity && (*/}
                                                        {/*            <span className="check"></span>*/}
                                                        {/*        )}*/}
                                                        {/*        {MSG.UPPRLWRCHAR}*/}
                                                        {/*    </li>*/}

                                                        {/*    <li*/}
                                                        {/*        className={`list-group-item  ${*/}
                                                        {/*            numberComplexity ? "text-success" : ""*/}
                                                        {/*        }`}*/}
                                                        {/*    >*/}
                                                        {/*        {numberComplexity ? (*/}
                                                        {/*            <span className="check"></span>*/}
                                                        {/*        ) : (*/}
                                                        {/*            ""*/}
                                                        {/*        )}*/}
                                                        {/*        {MSG.ONENUM}*/}
                                                        {/*    </li>*/}

                                                        {/*    <li*/}
                                                        {/*        className={`list-group-item  ${*/}
                                                        {/*            specialCharState && "text-success"*/}
                                                        {/*        }`}*/}
                                                        {/*    >*/}
                                                        {/*        {specialCharState ? (*/}
                                                        {/*            <span className="check"></span>*/}
                                                        {/*        ) : (*/}
                                                        {/*            ""*/}
                                                        {/*        )}*/}
                                                        {/*        {MSG.SPCLCHAR}*/}
                                                        {/*    </li>*/}
                                                        {/*</ul>*/}
                                                    </div>
                                                </div>
                                                <form
                                                    name="forgotpassword"
                                                    className="content"
                                                    onSubmit={handleSubmit(_onSubmit)}
                                                >
                                                    <div className="input-group mb-2" key="password">
                                                        <div className="row">
                                                            <div className="col">
                                                                <label htmlFor="newPassword">
                                                                    New Password
                                                                    <span className="mandatoryField">*</span>
                                                                </label>
                                                            </div>
                                                            <div className=" mb15 " key={type}>
                                                                <div className="">
                                                                    <span
                                                                        htmlFor="newPassword"
                                                                        className="form-label PasswordeyeIcon"
                                                                        onClick={() => toggleShow()}
                                                                    >
                                                                      <i
                                                                          className={`fa-solid ${
                                                                              type === "password"
                                                                                  ? "fa-eye-slash"
                                                                                  : "fa-eye"
                                                                          } `}
                                                                      ></i>
                                                                    </span>

                                                                    <input
                                                                        {...register("newPassword", {
                                                                            required: true,
                                                                        })}
                                                                        type={type}
                                                                        id="newPassword"
                                                                        onChange={() => typePassword("New Password")}
                                                                        className={`form-control ${
                                                                            errors.newPassword && "is-invalid"
                                                                        }`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errors.newPassword && (
                                                                <p className="text-danger">
                                                                    {errors.newPassword?.message}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="input-group" key="confirmPassword">
                                                        <div className={'row'}>
                                                            <div className={'col'}>
                                                                <label htmlFor="confirmpassword">
                                                                    Re-enter your new password
                                                                    <span className="mandatoryField">*</span>
                                                                </label>
                                                            </div>
                                                            <div className=" mb15 " key={confirmType}>
                                                                <div className="">
                                                              <span
                                                                  htmlFor="confirmPassword"
                                                                  className="form-label PasswordeyeIcon"
                                                              >
                                                                {getValues("confirmPassword") !== "" &&
                                                                    getValues("confirmPassword") !== undefined &&
                                                                    (getValues("newPassword") ===
                                                                    getValues("confirmPassword") ? (
                                                                        <span>
                                                                      <i className="fa-solid fa-check text-success"/>
                                                                    </span>
                                                                    ) : (
                                                                        <span>
                                                                      <i className="fa-solid fa-xmark text-danger"/>
                                                                    </span>
                                                                    ))}
                                                              </span>
                                                                    <input
                                                                        {...register("confirmPassword", {
                                                                            onBlur: (e) => {
                                                                                confirmPasswordShow("password");
                                                                            },
                                                                            required: true,
                                                                        })}
                                                                        className={`form-control ${
                                                                            errors.confirmPassword ? "is-invalid" : ""
                                                                        }`}
                                                                        type={"password"}
                                                                        id={"confirmPassword"}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errors.confirmPassword && (
                                                                <p className="text-danger">
                                                                    {errors.confirmPassword?.message}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="input-group full-width basic_details_form changepasswordbutns">
                                                        <div className={'row d-flex justify-content-between'}>
                                                            {/*<div className="form-group BDsubmitbutton d-flex">*/}
                                                                <div className="col-md-12 col-lg-6">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => resetForm()}
                                                                        className="btn btn-secondary btn-login d-block mt-3 cancelbutton align-middle"
                                                                    >
                                                                        <i className="fa-solid fa-xmark"></i> Cancel
                                                                    </button>
                                                                </div>
                                                                <div className={'col-md-12 col-lg-6'}>
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-secondary btn-login d-block mt-3 changepasswordbtn align-middle"
                                                                    >
                                                                        <i class="fa-regular fa-circle-check"></i>
                                                                        Reset Password
                                                                    </button>
                                                                </div>
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ResetPassword;
