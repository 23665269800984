/** @format */

import axios from "axios";
import { MONTHS, AGE } from "./DataObjects";
import * as image from "../resources/images";

export const getUnauthedAxios = () =>
  axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

export const getAxios = () => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return axios.create({ headers });
};

export const getMultipartAxios = () => {
  const token = getToken();
  const headers = {
    "Content-Type": undefined,
    enctype: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  return axios.create({ headers });
};

export const getPubMultipartAxios = () => {
  const headers = {
    "Content-Type": undefined,
    enctype: "multipart/form-data",
  };
  return axios.create({ headers });
};

export const getName = (string) => {
  let str = "";
  let middle = "";
  if (string?.firstName) {
    if (string?.middleName) {
      middle = string?.middleName.charAt(0).toUpperCase();
    }
    str =
      string?.firstName?.charAt(0)?.toUpperCase() +
      string?.firstName.slice(1) +
      " " +
      middle +
      " " +
      string?.lastName.charAt(0).toUpperCase() +
      string?.lastName.slice(1);
  }

  return str;
};

export const getProfileName = (string) => {
  let str = "";
  if (string) {
    if (string?.firstName) {
      str =
        string?.firstName?.charAt(0)?.toUpperCase() +
        " " +
        string?.lastName.charAt(0).toUpperCase();
    } else if (string !== "" && typeof string === "string") {
      let myArray = string && string?.split(" ");
      if (myArray.length > 1) {
        str =
          myArray[0]?.charAt(0)?.toUpperCase() +
          myArray[1]?.charAt(0)?.toUpperCase();
      } else {
        str = myArray[0]?.charAt(0)?.toUpperCase();
      }
    }
  }

  return str;
};

export const getCapitalized = (string) => {
  let str = "";
  if (string) {
    str = string.replace(
      /(^\w|\s\w)(\S*)/g,
      (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
    );
  }
  return str;
};

export const getUserDetails = (data, params) => {
  let details = [];
  if (data && params?.id) {
    if (data?.recordId === params?.id) {
      details = data?.records[0];
    } else {
      let child = data?.records[0]?.children;
      child.map((item) => {
        if (item.id === params.id) {
          details = item;
        }
      });
    }
  }
  return details;
};

export const getStrokeColor = (percent) => {
  if (percent <= 25) {
    return "#ffa41c";
  } else if (percent > 25 && percent <= 50) {
    return "##ffa41c";
  } else if (percent > 50 && percent <= 75) {
    return "#ffa41c";
  } else if (percent > 75) {
    return "#ffa41c";
  }
};

export const getToken = () => {
  let accessToken = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    accessToken = token;
  }
  return accessToken;
};

export const totalViews = (x) => {
  if (isNaN(x)) return x;
  if (x < 9999) {
    return x;
  }
  if (x < 1000000) {
    return Math.round(x / 1000) + "K";
  }
  if (x < 10000000) {
    return (x / 1000000).toFixed(2) + "M";
  }

  if (x < 1000000000) {
    return Math.round(x / 1000000) + "M";
  }

  if (x < 1000000000000) {
    return Math.round(x / 1000000000) + "B";
  }
  return "1T+";
};

export const getCounts = (userData, userType, interest) => {
  let myCount = 0;
  let childArray = [];

  if (userType === "parent") {
    if (userData?.role?.name === "PARENT") {
      if (userData?.email && userData?.mobile) {
        myCount++;
      }
      if (userData?.experiences?.length > 0) {
        myCount++;
      }
      if (userData?.educations?.length > 0) {
        myCount++;
      }
      if (userData?.interests?.length > 0) {
        myCount++;
      }
    }
    return myCount;
  } else if (userType === "child") {
    userData &&
      userData.map((value, index) => {
        let childCount = 0;
        if (value?.email && value?.mobile) {
          childCount++;
        }
        if (value?.schoolDetails?.school) {
          childCount++;
        }
        if (value?.enrichmentDetails?.length > 0) {
          childCount++;
        }
        if (value?.interests?.length > 0) {
          childCount++;
        }
        childArray[index] = childCount;
      });
    return childArray;
  }
};

export function textTrim(value, number) {
  let string = "";
  if (value && value.length > number) {
    string = value.substring(0, number) + "...";
  } else {
    string = value;
  }
  return string;
}

export function getSequnceSort(data) {
  if (data) {
    return (
      data &&
      data?.sort((a, b) =>
        b.sequence === null || a.sequence < b.sequence
          ? -1
          : a.sequence === null || a.sequence > b.sequence
          ? 1
          : a.sequence === b.sequence
          ? 0
          : Infinity
      )
    );
  }
}

export function getHostName(url) {
  try {
    var website = new URL(url);
    return website.hostname;
  } catch (error) {
    return url;
  }
}

export function getJoinedMonth(date) {
  if (date) {
    var start = new Date(date);
    var year = start.getFullYear();
    var month = start.getMonth();
    var monthName = MONTHS.filter((data) => data?.value === month);
    return monthName[0]?.key + " " + year;
  }
}

export function fineObject(obj) {
  const results = obj.filter((element) => {
    if (Object.keys(element).length !== 0) {
      return true;
    }

    return false;
  });
  return results;
}

export function getUrlSegment() {
  let url = window.location.pathname;
  var segment = url.split("/");
  var filteredSegment = segment.filter(function (el) {
    return el !== "";
  });
  return filteredSegment;
}

export function SelectPickerVal(object, type) {
  if (object) {
    let Array = [];
    object.map((vl, ky) => {
      if (type === "label") {
        Array.push({
          label: getCapitalized(vl?.name),
          value: getCapitalized(vl?.name),
        });
      } else if (type === "country") {
        Array.push({ label: getCapitalized(vl?.name), value: vl?.isoCode });
      } else if (type === "city") {
        Array.push({ label: getCapitalized(vl?.name), value: vl?.name });
      } else if (type === "lessonResource") {
        Array.push({
          label: vl?.lesson,
          value: vl?.modLessId,
          module: vl?.module,
        });
      } else if (type === "grade") {
        if (vl?.name == 6 || vl?.name == 7 || vl?.name == 8) {
          Array.push({ label: getCapitalized(vl?.name), value: vl?.id });
        }
      } else if (type === "studentGrade") {
        Array.push({ label: getCapitalized(vl?.name), value: vl?.id });
      } else {
        Array.push({ label: getCapitalized(vl?.name), value: vl?.id });
      }
    });

    return Array;
  }
}

export function SelectPickerName(object, type) {
  if (object) {
    let Array = [];
    object.map((vl, ky) => {
      if (type === "label") {
        Array.push({
          label: getCapitalized(vl?.name),
          value: getCapitalized(vl?.name),
        });
      } else {
        Array.push({
          label: getCapitalized(vl?.name),
          value: getCapitalized(vl?.name),
        });
      }
    });

    return Array;
  }
}

export function generateMonth(data) {
  var max;
  var lab;
  MONTHS.map((val, i) => {
    max = val.value;
    lab = val.label;
  });
  var min = max - 12;
  let studentMonth = [];
  for (var i = max; i > min; i--) {
    if (parseInt(i) > parseInt(data)) {
      let mon;
      MONTHS.map((val) => {
        if (val.value === i) {
          mon = val.label;
        }
      });
      studentMonth.push({ label: mon, value: i });
    }
  }
  return studentMonth;
}

export function generateArrayOfFromYears(offYear, startMonth, endMonth) {
  var max = new Date().getFullYear();
  var min = max - 49;
  let studentYear = [];
  if (startMonth >= endMonth) {
    for (var i = max; i > min; i--) {
      if (parseInt(i) > parseInt(offYear)) {
        studentYear.push({ label: i, value: i });
      }
    }
  } else if (startMonth < endMonth) {
    for (var i = max; i >= min; i--) {
      if (parseInt(i) >= parseInt(offYear)) {
        studentYear.push({ label: i, value: i });
      }
    }
  }
  return studentYear;
}

export const generateArrayOfYears = () => {
  var max = new Date().getFullYear();
  var min = max - 49;
  var years = [];
  for (var i = max; i >= min; i--) {
    years.push({ label: i, value: i });
  }
  return years;
};

export function formatMobNo(phoneNumberString) {
  if (phoneNumberString) {
    var code = phoneNumberString.split("##");

    return code[0] + code[1];
  }
  return null;
}

export function dateFormat(dateObj) {
  if (dateObj) {
    let monthStr = dateObj.getMonth() + 1;
    let yearStr = dateObj.getFullYear();
    let dateStr = dateObj.getDate();
    return yearStr + "/" + monthStr + "/" + dateStr;
  }
  return null;
}

export function dateInMonthFormat(dateObj) {
  if (dateObj) {
    let monthStr = dateObj.getMonth() + 1;
    let yearStr = dateObj.getFullYear();
    let dateStr = dateObj.getDate();
    return monthStr + "/" + dateStr + "/" + yearStr;
  }
  return null;
}

export function digitFormat(number) {
  return new Intl.NumberFormat("en-US", { maximumSignificantDigits: 3 }).format(
    number
  );
}

export function formatPhoneNumber(value) {
  if (!value) return value;

  // clean th e input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

export function getImage(value) {
  if (value) {
    return value;
  } else {
    return image.noImage;
  }
}

export function htmlStrip(object) {
  if (object) {
    return object.replace(/<[^>]*>?/gm, "");
  }
}
