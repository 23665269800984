/** @format */

import React, { useEffect, Suspense } from "react";

import { BrowserRouter, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AOS, useJwt } from "./utils/Packages";
import { PATHS } from "./utils";
import { SignUp, WebHome } from "./components/base";
import { Error403, Error404 } from "./components/errors";

import { VerifyUser } from "./components/auth";

import ResetPassword from "./components/base/ResetPassword";
import { logoutAction } from "./redux/actions/APIs";
import { PrivateRoute, PublicRoute } from "./utils";
import Home from "./components/home/Home";
import ChangePassword from "./components/profile/ChangePassword";
import BasicDetails from "./components/profile/BasicDetails";
import NewresetPassword from "./components/base/NewresetPassword";
import { CmsPage, ContactUs, NewsDetails, News } from "./components/page";
import {
  PropertyListing,
  AddProperty,
  SearchProperty,
  PropertyDetails,
  PropertyOffer,
  EditProperty,
} from "./components/property";
const App = () => {
  const dispatch = useDispatch();

  const { isExpired } = useJwt(localStorage.getItem("access_token"));

  AOS.init();

  useEffect(() => {
    if (isExpired && localStorage.getItem("access_token")) {
      dispatch(logoutAction());
    }
  }, [isExpired]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path={PATHS.LANDINGPAGE} component={WebHome} />
          <PublicRoute
            path={PATHS.CMSPAGE}
            component={CmsPage}
            restricted={true}
          />
          <PublicRoute
            path={PATHS.PROPERTYDETAIL}
            component={PropertyDetails}
          />
          <PublicRoute path={PATHS.NEWSDETAILS} component={NewsDetails} />
          <PublicRoute path={PATHS.NEWS} component={News} />
          <PublicRoute path={PATHS.CONTACTUS} component={ContactUs} />
          <PublicRoute
            path={PATHS.NEWRESETPASSWORD}
            component={NewresetPassword}
          />
          <PublicRoute path={PATHS.VERIFYPASSWORD} component={VerifyUser} />
          <PublicRoute path={PATHS.USERSIGNUP} component={SignUp} />
          <PublicRoute path={PATHS.RESETPASSWORD} component={ResetPassword} />
          <PublicRoute path={PATHS.SEARCHPROPERTY} component={SearchProperty} />
          <PrivateRoute path={PATHS.PROPERTYLIST} component={PropertyListing} />
          <PrivateRoute path={PATHS.OFFERLIST} component={PropertyOffer} />
          <PrivateRoute path={PATHS.ADDPROPERTY} component={AddProperty} />
          <PrivateRoute path={PATHS.EDITPROPERTY} component={EditProperty} />

          <PrivateRoute exact path={PATHS.HOME} component={Home} />
          <PrivateRoute
            path={PATHS.CHANGEPASSWORD}
            component={ChangePassword}
          />
          <PrivateRoute
            exact
            path={PATHS.BASICDETAILS}
            component={BasicDetails}
          />
          <PrivateRoute path={PATHS.ACCESS_DENIED} component={Error403} />
          <PublicRoute path="*" component={Error404} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
