/** @format */

import React, { useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { PATHS } from "../../utils";
import * as image from "../../resources/images";

const Sidebar = () => {
  const ref = useRef(null);

  return (
    <>
      <ul class="navbar-nav sidebar accordion flex-column" id="nav_accordion">
        <li class="nav-item">
          <Link class="nav-link" to={PATHS.HOME}>
            {" "}
            Home{" "}
          </Link>
        </li>
        {/* <li class="nav-item">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target="#menu_item1"
            href="#"
          >
            <span>Submenu links </span>
            <i class="fa-solid fa-chevron-down"></i>
          </a>
          <ul
            id="menu_item1"
            class="submenu collapse"
            data-bs-parent="#nav_accordion"
          >
            <li>
              <a class="nav-link" href="#">
                Submenu item 1{" "}
              </a>
            </li>
            <li>
              <a class="nav-link" href="#">
                Submenu item 2{" "}
              </a>
            </li>
            <li>
              <a class="nav-link" href="#">
                Submenu item 3{" "}
              </a>
            </li>
          </ul>
        </li> */}

        <li class="nav-item">
          <Link to={PATHS.PROPERTYLIST} class="nav-link" href="#">
            {" "}
            Property Listing
          </Link>
        </li>

        {/* <div class="text-center d-none d-md-inline">
          <button class="rounded-circle border-0" id="sidebarToggle"></button>
      </div>   */}
      </ul>
    </>
  );
};

export default Sidebar;
