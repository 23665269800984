/** @format */

import React, { useEffect } from "react";
import { swal } from "../../utils/Packages";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "./Topbar";
import { SecureTopbar } from "../base";
import Footer from "./Footer";
import SignInPG from "../base/SigninPG";
import { SignUp } from "../base";
import { isLoading, resetResponse, resetError } from "../../redux/actions";
import ForgotPassword from "../base/ForgotPasswordNew";
import { PATHS } from "../../utils";

const WebLayout = ({ children }) => {
  const { error, signinresponse, modalData, response } = useSelector(
    (state) => state.collections
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      new swal({
        position: "top-center",
        icon: "error",
        title: "Error",
        timer: 5000,
        text: error,
        type: "error",
      });
      setTimeout(() => {
        dispatch(resetError());
        dispatch(isLoading(false));
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (response) {
      new swal({
        position: "top-center",
        icon: "success",
        title: "Success",
        timer: 5000,
        text: response?.message,
        type: "success",
      });
      setTimeout(() => {
        dispatch(resetResponse());
        dispatch(isLoading(false));
      }, 5000);
    }
  }, [response]);

  useEffect(() => {
    if (signinresponse && signinresponse?.success) {
      window.location.assign(PATHS.HOME);
      window.scrollTo(0, 0);
    }
  }, [signinresponse]);

  return (
    <>
      {modalData?.type === "SignUp" && <SignUp />}
      {modalData?.type === "SignIn" && <SignInPG />}
      {modalData?.type === "forgotPassword" && <ForgotPassword />}
      {localStorage.getItem("access_token") ? <SecureTopbar /> : <Topbar />}

      {children}
      <Footer />
    </>
  );
};

export default WebLayout;
