/** @format */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as image from "../../resources/images";
import WebLayout from "../layout/WebLayout";

import { getLandingPage, searchProperty } from "../../redux/actions/APIs";
import { PATHS } from "../../utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { htmlStrip, textTrim } from "../../utils/helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WebHome = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { landingData, searchPropertyData } = useSelector(
    (state) => state.collections
  );
  useEffect(() => {
    dispatch(searchProperty());
    dispatch(getLandingPage());
  }, []);

  const onTextChanged = (e) => {
    e.preventDefault();
    const value = document.getElementById("search").value;
    dispatch(searchProperty(value));
  };
  useEffect(() => {
    if (searchPropertyData && document.getElementById("search").value) {
      history.push({
        pathname: PATHS.SEARCHPROPERTY,
        state: {
          data: searchPropertyData,
          keyword: document.getElementById("search").value,
        },
      });
    }
  }, [searchPropertyData]);

  return (
    <WebLayout>
      <section id="hero" class="d-flex align-items-center">
        <div class="container">
          <h2>
            Property asset tracking software built <br />
            for rental property owners and accountants
          </h2>
          <div class="banner-search p-3">
            <form class="">
              <div class="inner-form">
                <div class="input-field second-wrap">
                  <input
                    type="text"
                    id="search"
                    placeholder="Find My Property"
                  />
                </div>
                <div class="input-field third-wrap">
                  <button
                    class="btn-search"
                    type="text"
                    onClick={onTextChanged}
                  >
                    <img src={image.searchIcon} alt="" />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="text-center mt-3">
            <a href="#" class="hiw-link">
              How It Work
            </a>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="team" class="team">
          <div class="container">
            <div class="section-title">
              <h3>Prepare your 1040 Form 10x Faster</h3>
              <p>Property deductions without the hassle.</p>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                <div class="member">
                  <div class="member-img">
                    <img src={image.property1} class="img-fluid" alt="" />
                  </div>
                  <div class="member-info">
                    <h4>Walter White</h4>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                <div class="member">
                  <div class="member-img">
                    <img src={image.property1} class="img-fluid" alt="" />
                  </div>
                  <div class="member-info">
                    <h4>Sarah Jhonson</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" class="about section-bg pb-5">
          <div class="container">
            <div class="section-title logo-heading">
              <h3>UNITWATCH</h3>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="img-overlap">
                  <img src={image.image1} class="img-fluid" alt="" />
                  <img src={image.image1} class="img-fluid img2pos" alt="" />
                </div>
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center">
                <p>About UnitWatch</p>
                <h3>
                  A Smarter & More <br />
                  Affordable Way To Track <br />
                  Your Assets
                </h3>
                <p>
                  Aiusmod tempor incididunt ut labore sed dolore magna aliquay
                  dnim ad minim veniam quis nostrud exercitation ullamco laboris
                  nisi ut aliquip ex ea reprehen deritin voluptate.
                </p>

                <ul>
                  <li>
                    <h5>
                      Tax Readiness <br />
                      Maximum Benefit
                    </h5>
                    <p>
                      Velit esse cillum dolore ipsum eu fugiat nulla pariatur.
                    </p>
                  </li>
                  <li>
                    <h5>
                      Stay On Top <br />
                      Of Your Write-Offs
                    </h5>
                    <p>
                      Excepteur sint occaecat cupidat non proident sunt iny.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="howItWork" class="how-it-works">
          <div class="container">
            <div class="section-title">
              <p>Find The Right Equipment</p>
              <h3>How It Works</h3>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-3">
                <div class="hiw-box">
                  <div class="hiwb-icon">
                    <img src={image.homeIcon} alt="" />
                  </div>
                  <div class="hiwb-text">
                    <div class="hiwb-number">1</div>
                    <h2>
                      Find Your <br />
                      Property
                    </h2>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="hiw-box">
                  <div class="hiwb-icon">
                    <img src={image.homeIcon2} alt="" />
                  </div>
                  <div class="hiwb-text">
                    <div class="hiwb-number">2</div>
                    <h2>
                      Add Your <br />
                      Assets
                    </h2>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="hiw-box">
                  <div class="hiwb-icon">
                    <img src={image.homeIcon3} alt="" />
                  </div>
                  <div class="hiwb-text">
                    <div class="hiwb-number">3</div>
                    <h2>
                      Record <br />
                      Your Purchases
                    </h2>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="hiw-box">
                  <div class="hiwb-icon">
                    <img src={image.homeIcon4} alt="" />
                  </div>
                  <div class="hiwb-text">
                    <div class="hiwb-number">4</div>
                    <h2>
                      get A Tax-Ready <br />
                      1040
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="optimizing" class="optimizing section-bg-gray">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6 opt-heading">
                <h2>
                  We’ve Made Optimizing Your <br />
                  Write-Offs Simple
                </h2>
              </div>
              <div class="col-lg-6">
                <div class="opt-content ps-5">
                  <div class="opt-text">
                    <span>Let’s Talk</span>
                    <strong>236-799-5500</strong>
                  </div>
                  <a
                    href="javascriptL:void(0)"
                    class="black-btn d-inline-flex align-items-center justify-content-center"
                  >
                    <span>get started</span>
                    <img src={image.whiteArrow} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonial" class="testimonial">
          <div class="container">
            <div class="section-title">
              <p>Find The Right Equipment</p>
              <h3>What Our Customers Are Saying</h3>
            </div>
            <OwlCarousel items={2} className="owl-theme" loop margin={10} nav>
              {landingData?.data?.testimonial &&
                landingData?.data?.testimonial.map((vl, ky) => (
                  <div class="item" key={ky}>
                    <div class="text-coma">
                      <img src={image.comma} alt="" class="img-fluid" />
                    </div>
                    <div class="content">
                      <h5>{vl?.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{ __html: vl?.description }}
                      />
                    </div>
                    <div class="author-info">
                      <div class="thumb">
                        <img
                          src={
                            "https://app.unitwatch.com/" + vl?.testimonial_image
                          }
                          alt="Testimonial"
                        />
                      </div>
                      <div class="info-rating">
                        <div class="info">
                          <h6 class="title">{vl?.name}</h6>
                          <span class="subtitle">{vl?.designation}</span>
                        </div>
                        <div class="rating-icon">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
        </section>

        <section id="news" class="news section-bg-gray">
          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <div class="section-titleL">
                  <p>UnitWatch</p>
                  <h3>Latest News & Updates</h3>
                </div>
              </div>
              <div class="col-md-4 text-end">
                <a
                  href="javascriptL:void(0)"
                  class="black-trans-btn d-inline-flex align-items-center justify-content-center"
                >
                  <span class="me-2">view all news</span>
                  <img src={image.blackArrow} alt="" />
                </a>
              </div>
            </div>

            <div class="row">
              {landingData?.data?.news &&
                landingData?.data?.news.map((val, ky) => (
                  <div class="col-lg-4 col-md-6" key={ky}>
                    {console.log(val?.slug)}
                    <div class="box">
                      <div class="lnb-pic">
                        <div class="tag-line">{val?.category}</div>
                        <img
                          src={"https://app.unitwatch.com/" + val.news_image}
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class="news-content">
                        <div class="posted-by">
                          <strong>22</strong> |
                          <span>
                            By <a href="javascript:void(0)">Antek Admin</a>
                          </span>
                        </div>
                        <h3>{val?.title}</h3>
                        <div>{textTrim(htmlStrip(val?.description), 150)}</div>
                      </div>
                      <div class="news-read-more">
                        <Link to={PATHS.NEWSDETAILS_STR + val?.slug}>
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </main>
    </WebLayout>
  );
};

export default WebHome;
