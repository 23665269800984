/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MSG } from "../../utils/Messages";
import { Country, State, City } from "country-state-city";
import { SelectPicker, Controller } from "../../utils/Packages";
import { ZIP_REGEX } from "../../utils/Regex";
import { SelectPickerVal } from "../../utils/helper";

const AddressForm = (
  { register, errors, setValue, addressData, control, getValues },
  props
) => {
  console.log(addressData, "bbbb");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const { getSelectedUser, loggedInUser, billingDetails } = useSelector(
    (state) => state.collections
  );

  useEffect(() => {
    setAddress(addressData?.address);
  }, [addressData]);

  useEffect(() => {
    setValue("address", addressData?.address);
    setValue("city", addressData?.city);

    setValue("zip_code", addressData?.zip_code);
    let coun = Country.getAllCountries().filter(
      (data) => data?.name === addressData?.country
    );

    let stte = State.getAllStates().filter(
      (data) => data?.countryCode === coun[0]?.isoCode
    );
    setStates(stte);

    let citiess = City.getAllCities().filter(
      (data) =>
        data?.stateCode === stte[0]?.name &&
        data?.countryCode === coun[0]?.isoCode
    );

    setCities(citiess);

    setValue("country", coun[0]?.isoCode);
  }, [addressData]);
  const changeCountry = (id) => {
    setSelectedCountry(id);
    let state = State.getAllStates().filter((data) => data?.countryCode === id);
    setStates(state);
  };
  const changeState = (id) => {
    let citiess = City.getAllCities().filter(
      (data) => data?.stateCode === id && data?.countryCode === selectedCountry
    );
    setCities(citiess);
  };
  return (
    <>
      <div className="row" key={Math.random()}>
        <div className="col-md-6 pos-rel mb-3">
          <label className="mb-2">
            Address 1<span className="mandatoryField">*</span>
          </label>
          <input
            type="text"
            className={`form-control ${
              address ? "" : errors.city ? "is-invalid" : ""
            }`}
            defaultValue={address}
            placeholder="Address"
            {...register("address", {
              required: true,
            })}
          />
          {errors.city && <p className="text-danger">{MSG.ADDR1REQ}</p>}
        </div>
        <div className="col-md-6 pos-rel mb-3">
          <label className="mb-2">
            City<span className="mandatoryField">*</span>
          </label>
          <Controller
            {...register("city", {
              required: true,
            })}
            control={control}
            render={({ field: { onChange, value } }) => {
              onChange = (event) => {
                setValue("city", event);
                clearErrors("city");
              };
              return (
                <SelectPicker
                  onChange={onChange}
                  className={`form-control ${errors.city ? "is-invalid" : ""}`}
                  data={SelectPickerVal(cities, "city")}
                  searchable={true}
                  cleanable={false}
                />
              );
            }}
          />
          {errors.city && <p className="text-danger">{MSG.CITYREQ}</p>}
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 pos-rel mb-3">
          <label className="mb-2">
            State<span className="mandatoryField">*</span>
          </label>
          <Controller
            {...register("state", {
              required: true,
            })}
            control={control}
            render={({ field: { onChange, value } }) => {
              onChange = (event) => {
                changeState(event);
                setValue("state", event);
                clearErrors("state");
              };
              return (
                <SelectPicker
                  onChange={onChange}
                  className={`form-control ${errors.state ? "is-invalid" : ""}`}
                  data={SelectPickerVal(states, "country")}
                  searchable={true}
                  cleanable={false}
                />
              );
            }}
          />
          {errors.state && <p className="text-danger">{MSG.STATEREQ}</p>}
        </div>
        <div className="col" key={Math.random()}>
          <label>
            Country<span className="mandatoryField">*</span>
          </label>
          <Controller
            {...register("country", {
              required: true,
            })}
            control={control}
            render={({ field: { onChange, value } }) => {
              onChange = (event) => {
                console.log(event);
                changeCountry(event);
                setValue("country", event);
              };
              return (
                <SelectPicker
                  onChange={onChange}
                  className={`form-control ${
                    errors.country ? "is-invalid" : ""
                  }`}
                  defaultValue={"IN"}
                  data={SelectPickerVal(Country.getAllCountries(), "country")}
                  searchable={true}
                  cleanable={false}
                />
              );
            }}
          />
          {errors.country && <p className="text-danger">{MSG.COUNTRYREQ}</p>}
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 pos-rel mb-3">
          <label className="mb-2">
            Zipcode<span className="mandatoryField">*</span>
          </label>
          <input
            type="text"
            maxLength="10"
            className={`form-control ${errors.zip_code ? "is-invalid" : ""}`}
            placeholder="Zipcode"
            {...register("zip_code", {
              required: {
                value: true,
                message: MSG.ZCODE,
              },
              pattern: {
                value: ZIP_REGEX,
                message: MSG.INZCODE,
              },
            })}
          />
        </div>

        {errors.zip_code && (
          <p className="text-danger">{errors.zip_code.message}</p>
        )}
      </div>
    </>
  );
};

export default AddressForm;
