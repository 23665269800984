/** @format */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as image from "../../resources/images";
import { getPropertyDetail } from "../../redux/actions/APIs";
import WebLayout from "../layout/WebLayout";
import SubmitPropertyOffer from "./SubmitPropertyOffer";
import { useState } from "react";
import { showModal } from "../../redux/actions";

const PropertyDetails = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const param = useParams();
  const { propertyData, response, modalData } = useSelector(
    (state) => state.collections
  );
  useEffect(() => {
    dispatch(getPropertyDetail(param?.slug));
  }, []);

  let data = propertyData?.data;

  useEffect(() => {
    if (response) {
      dispatch(showModal());
    }
  }, [response]);
  console.log(data?.get_owner);
  return (
    <WebLayout>
      <section class="breadcrumbs section-bg-gray">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <h2>{data?.title}</h2>
            <ol>
              <li>
                <a href="#">Listing</a>
              </li>
              <li>Details</li>
            </ol>
          </div>
        </div>
      </section>
      <section class="inner-page-content">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-7 mb-4">
              <div class="box bx-shadow mb-3">
                <div class="lnb-pic">
                  {data?.listing_image.length > 0 ? (
                    <img
                      src={
                        "https://app.unitwatch.com/" +
                        data?.listing_image[0]?.image_path
                      }
                      alt=""
                      class="img-fluid"
                    />
                  ) : (
                    <img src={image.noImage} alt="" class="img-fluid" />
                  )}
                </div>
                <div class="news-content">
                  <h3>{data?.title}</h3>
                  <div class="map">
                    <a href="javascript:void(0)">
                      <i class="fa fa-map-marker"></i> {data?.address},
                      {data?.city}, {data?.state}, {data?.country}
                      {data?.zip_code}
                    </a>
                    <p className="mt-2 b-date">Year Build {data?.year_built}</p>
                  </div>

                  <p className="mb-3">{data?.description}</p>
                  <ul className="fc-list-2">
                    <li>
                      <a
                        href={"https://app.unitwatch.com/" + data?.floar_plan}
                        download
                        target={"_blank"}
                      >
                        <i class="fa-solid fa-building-wheat"></i>
                        <span> Floor Plan </span>
                      </a>
                    </li>
                    <li>
                      <a href={data?.video_url} target={"_blank"}>
                        <i class="fa-solid fa-video"></i>
                        <span>View Video</span>
                      </a>
                    </li>
                  </ul>
                  <hr />
                  <ul className="fc-list">
                    <li>
                      <i class="fa fa-bed" aria-hidden="true"></i>
                      <span>Bedroom :</span>
                      <strong>{data?.bedrooms}</strong>
                    </li>
                    <li>
                      <i class="fa fa-bath" aria-hidden="true"></i>
                      <span>Bathroom :</span>
                      <strong>{data?.bathrooms}</strong>
                    </li>
                    <li>
                      <i class="fa fa-car" aria-hidden="true"></i>
                      <span>Garage(Size) :</span>
                      <strong>
                        {data?.garage} {data?.garage_size}
                      </strong>
                    </li>
                    <li>
                      <i class="fa fa-map" aria-hidden="true"></i>
                      <span>Landarea :</span>
                      <strong>{data?.land_area}</strong>
                    </li>
                    <li>
                      <i class="fa fa-map" aria-hidden="true"></i>
                      <span>Sq. Ft :</span>
                      <strong>{data?.square_footage}</strong>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-end">
                    <button
                      class="black-btn"
                      onClick={() => dispatch(showModal({ type: "OfferForm" }))}
                    >
                      Submit Offer
                    </button>
                  </div>
                </div>
              </div>
              <div class="post-author">
                <div class="pa-title mb-3">
                  <h2>Property Owner</h2>
                </div>
                <div class="pa-content">
                  <div>
                    <h4>
                      {data?.get_owner?.name + " " + data?.get_owner?.last_name}
                    </h4>
                    <em>{data?.get_owner?.email}</em>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-5 mb-4">
              <h3 class="mb-3">Featured</h3>
              <div class="box post-item bx-shadow mb-3">
                <img src={image.camera} alt="" class="flex-shrink-0" />
                <div>
                  <h5>
                    <a href="javascript:void(0)">
                      Nihil blanditiis at in nihil autem
                    </a>
                  </h5>
                  <time>Jan 1, 2023</time>
                </div>
              </div>
              <div class="box post-item bx-shadow mb-3">
                <img src={image.camera} alt="" class="flex-shrink-0" />
                <div>
                  <h5>
                    <a href="javascript:void(0)">Lorem ipsum dolor sit mate</a>
                  </h5>
                  <time>Jan 1, 2023</time>
                </div>
              </div>
              <div class="box post-item bx-shadow mb-3">
                <img src={image.camera} alt="" class="flex-shrink-0" />
                <div>
                  <h5>
                    <a href="javascript:void(0)">
                      Lorem ipsum dolor sit amet consectetur adipisicing.
                    </a>
                  </h5>
                  <time>Jan 1, 2023</time>
                </div>
              </div>
              <div class="box post-item bx-shadow mb-3">
                <img src={image.camera} alt="" class="flex-shrink-0" />
                <div>
                  <h5>
                    <a href="javascript:void(0)">
                      Doloremque necessitatibus hic placeat praesentium.
                    </a>
                  </h5>
                  <time>Jan 1, 2023</time>
                </div>
              </div>
              <div class="box post-item bx-shadow mb-3">
                <img src={image.camera} alt="" class="flex-shrink-0" />
                <div>
                  <h5>
                    <a href="javascript:void(0)">
                      Quas accusantium aliquam temporillo.
                    </a>
                  </h5>
                  <time>Jan 1, 2023</time>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {console.log(modalData)}
      {modalData?.type === "OfferForm" && <SubmitPropertyOffer />}
    </WebLayout>
  );
};

export default PropertyDetails;
