/** @format */

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Country, State, City} from "country-state-city";
import {
    EMAIL_REGEX,
    CHAR_REGEX,
    SPECIAL_CHAR,
    GET_LOWER_CASE,
    GET_UPPER_CASE,
    GET_NUMBER,
} from "../../utils/Regex";
import {
    useForm,
    Controller,
    SelectPicker,
    Modal,
    Button,
} from "../../utils/Packages";

import {userSignup} from "../../redux/actions/APIs";
import * as image from "../../resources/images";
import {isLoading, showModal, resetEmailResponse} from "../../redux/actions";

import "rsuite/dist/rsuite.css";
import {SelectPickerVal} from "../../utils/helper";
import {MSG} from "../../utils/Messages";

const SignUp = () => {
    const {loading, validateUser, signupresponse} = useSelector((state) => state.collections);

    const [userFormState, setUserFormState] = useState("parent");
    const [type, setType] = useState("password");

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [pass, confirmPasswordShow] = useState("");
    const [passwordComplexity, setPasswordComplexity] = useState(false);
    const [eightState, getEightState] = useState(false);
    const [specialCharState, getSpecialCharState] = useState(false);
    const [upperCaseComplexity, getUpperCaseComplexity] = useState(false);
    const [lowerCaseComplexity, getLowerCaseComplexity] = useState(false);
    const [numberComplexity, getNumberComplexity] = useState(false);
    const [signupSuccess, setSignupSuccess] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        setError,
        clearErrors,
        formState: {errors},
    } = useForm({mode: "onTouched"});

    const toggleShow = () => {
        setType(type === "text" ? "password" : "text");
    };

    const dispatch = useDispatch();
    const [selectedCountry, setSelectedCountry] = useState();
    const changeCountry = (id) => {
        setSelectedCountry(id);
        let state = State.getAllStates().filter((data) => data?.countryCode === id);
        setStates(state);
    };
    const changeState = (id) => {
        let citiess = City.getAllCities().filter(
            (data) => data?.stateCode === id && data?.countryCode === selectedCountry
        );
        setCities(citiess);
    };

    const typePassword = (e) => {
        setPasswordComplexity(false);
        let item = e.target.value;

        if (item.length > 0) {
            clearErrors(["password"]);
        } else {
            setError("password", {type: "custom", message: MSG.PASSREQ});
        }

        if (item.length >= 8) {
            getEightState(true);
        } else {
            getEightState(false);
            setPasswordComplexity(true);
        }
        if (SPECIAL_CHAR.test(item)) {
            getSpecialCharState(true);
        } else {
            setPasswordComplexity(true);
            getSpecialCharState(false);
        }

        if (GET_LOWER_CASE.test(item)) {
            getLowerCaseComplexity(true);
        } else {
            setPasswordComplexity(true);
            getLowerCaseComplexity(false);
        }

        if (GET_UPPER_CASE.test(item)) {
            getUpperCaseComplexity(true);
        } else {
            setPasswordComplexity(true);
            getUpperCaseComplexity(false);
        }
        if (GET_NUMBER.test(item)) {
            getNumberComplexity(true);
        } else {
            setPasswordComplexity(true);
            getNumberComplexity(false);
        }
    };
    const formReset = () => {
        reset();

        setValue("country", countryId);
        getEightState(false);
        getSpecialCharState(false);
        getUpperCaseComplexity(false);
        getLowerCaseComplexity(false);
        getNumberComplexity(false);
    };

    const _onParentSubmit = (values) => {
        if (passwordComplexity) {
            return false;
        }
        if (validateUser && validateUser?.message) {
            return false;
        }
        dispatch(isLoading(true));
        let formData = {
            name: values.firstName,
            lastName: values.lastName,
            country: values.country,
            state: values.state,
            city: values.city,
            zip_code: values.zip_code,
            email: values.email,
            phone: values.phone,
            password: values.password,
            type: "property_owner",
            address: "values.address",
        };
        dispatch(userSignup(formData));
    };

    const checkPassword = () => {
        confirmPasswordShow("password");
    };

    useEffect(() => {
        dispatch(resetEmailResponse());
    }, []);

    useEffect(() => {
        if (signupresponse && signupresponse?.success === true) {
            setSignupSuccess(true);
        }
    }, [signupresponse]);

    // dispatch(getUserByUsername())
    const isValidEmail = (email) => {
        if (EMAIL_REGEX.test(email)) {
            return true;
        }
        return false;
    };

    const basicForm = (
        <div className="flex flex-wrap align-items-start">
            <div className="input-group mb-2">
                <div className="row">
                    <div className="col-md-4">
                        <label>
                            First Name<span className="mandatoryField">*</span>
                        </label>
                    </div>
                    <div className="col-md-8">
                        <div className="form-group">
                            <input
                                type="text"
                                // placeholder="First Name"
                                className={`form-control ${
                                    errors.firstName ? "is-invalid" : ""
                                }`}
                                {...register("firstName", {
                                    required: {
                                        value: true,
                                        message: MSG.FNAMEREQ,
                                    },
                                    pattern: {
                                        value: CHAR_REGEX,
                                        message: MSG.ALPHAREQ,
                                    },
                                    maxLength: {
                                        value: 14,
                                        message: MSG.MAX15CHREQ,
                                    },
                                })}
                            />

                            <div className="invalid-feedback">
                                {errors?.firstName?.message}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-group mb-2">
                <div className="row">
                    <div className="col-md-4">
                        <label>
                            Last Name<span className="mandatoryField">*</span>
                        </label>
                    </div>
                    <div className="col-md-8">
                        <div className="form-group">
                            <input
                                type="text"
                                // placeholder="Last Name"
                                className={`form-control ${
                                    errors.lastName ? "is-invalid" : ""
                                }`}
                                {...register("lastName", {
                                    required: {
                                        value: true,
                                        message: MSG.LNAMEREQ,
                                    },
                                    pattern: {
                                        value: CHAR_REGEX,
                                        message: MSG.ALPHAREQ,
                                    },
                                    maxLength: {
                                        value: 14,
                                        message: MSG.MAX15CHREQ,
                                    },
                                })}
                            />
                            <div className="invalid-feedback">
                                {errors?.lastName?.message}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-group mb-2">
                <div className="row">
                    <div className="col-md-4">
                        <label>
                            Email/Username<span className="mandatoryField">*</span>
                        </label>
                    </div>
                    <div className="col-md-8">
                        <div className="form-group">
                            <input
                                type="text"
                                // placeholder="Email/Username"
                                className={`form-control ${
                                    errors.email || validateUser?.message ? "is-invalid" : ""
                                }`}
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: MSG.EMAILREQ,
                                    },
                                    pattern: {
                                        value: EMAIL_REGEX,
                                        message: MSG.INVEMAILREQ,
                                    },
                                })}

                                // onBlur={(e)=>handleEmail(e)}
                            />
                            <div className="invalid-feedback">{errors?.email?.message}</div>
                            <div className="invalid-feedback">{validateUser?.message}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-group mb-2" key="password">
                <div className="row">
                    <div className="col-md-4">
                        <label>
                            Password<span className="mandatoryField">*</span>
                        </label>
                    </div>
                    <div className="col-md-8">
                        <div className="form-group" key={type}>
              <span
                  htmlFor="password"
                  className="form-label PasswordeyeIcon"
                  onClick={() => toggleShow()}
              >
                <i
                    className={`fa-solid ${
                        type === "password" ? "fa-eye-slash" : "fa-eye"
                    } `}
                ></i>
              </span>
                            <input
                                className={`form-control ${
                                    errors.password ? "is-invalid" : ""
                                }`}
                                {...register("password", {
                                    required: {
                                        value: true,
                                        message: MSG.PASSREQ,
                                    },
                                })}
                                type={type}
                                id={"getPassword"}
                                onChange={typePassword}
                            />
                            <div className="invalid-feedback">
                                {errors?.password?.message}
                                {passwordComplexity && MSG.STRNGPWD}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-group mb-2" key="confirmPassword">
                <div className="row">
                    <div className="col-md-4">
                        <label>
                            Confirm Password<span className="mandatoryField">*</span>
                        </label>
                    </div>
                    <div className="col-md-8">
                        <div className="form-group">
              <span
                  htmlFor="confirmPassword"
                  className="form-label PasswordeyeIcon"
                  key={Math.random()}
              >
                {getValues("confirmpassword") !== "" &&
                    getValues("confirmpassword") !== undefined &&
                    (getValues("password") === getValues("confirmpassword") ? (
                        <i className="fa-solid fa-check text-success"></i>
                    ) : (
                        <i className="fa-solid fa-xmark text-danger"></i>
                    ))}
              </span>
                            <input
                                className={`form-control ${
                                    errors.confirmpassword ? "is-invalid" : ""
                                }`}
                                // placeholder="Confirm Password"
                                {...register("confirmpassword", {
                                    onBlur: (e) => {
                                        checkPassword();
                                    },
                                    required: {
                                        value: true,
                                        message: MSG.CPASSREQ,
                                    },
                                    validate: (val) => {
                                        if (watch("password") != val) {
                                            return MSG.PWDNOTMTCH;
                                        }
                                    },
                                })}
                                type={`password`}
                                id={"confirmPassword"}
                            />
                            <div className="invalid-feedback">
                                {errors?.confirmpassword?.message}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex w-100 align-items-start mb-2">
                <div className="PasswordIntruction w-100">
                    <ul className="list-group">
                        <li className={`list-group-item bg-light d-flex`}>
                            {eightState ? (
                                <span className="check"></span>
                            ) : (
                                <div>
                                    <i className="fa fa-minus-circle circleIcon"/>
                                </div>
                            )}{" "}
                            {MSG.EIGHTCHAR}
                        </li>

                        <li className={`list-group-item bg-light d-flex`}>
                            {upperCaseComplexity && lowerCaseComplexity ? (
                                <span className="check"></span>
                            ) : (
                                <div>
                                    <i className="fa fa-minus-circle circleIcon"/>
                                </div>
                            )}
                            {MSG.UPPRLWRCHAR}
                        </li>

                        <li className={`list-group-item bg-light d-flex`}>
                            {numberComplexity ? (
                                <span className="check"></span>
                            ) : (
                                <div>
                                    <i className="fa fa-minus-circle circleIcon"/>
                                </div>
                            )}
                            {MSG.ONENUM}
                        </li>

                        <li className={`list-group-item bg-light d-flex`}>
                            {specialCharState ? (
                                <span className="check"></span>
                            ) : (
                                <div>
                                    <i className="fa fa-minus-circle circleIcon"/>
                                </div>
                            )}
                            {MSG.SPCLCHAR}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <Modal
                show={true}
                className="SignupPopup AddChildPopup"
                onHide={() => dispatch(showModal())}
            >
                <Modal.Body className="p-0">
                    <div className="SignupRegistration basicdetailsform in-up-form">
                        <Modal.Header>
                            <div class="modal-title flex h4">
                                {/*<img src={image.Signupicon} alt="" className="mr-2" />*/}
                                <i className="fa-solid fa-id-card mr-2 fontawsomeicon"></i>
                                <h3>Sign Up</h3>{" "}
                            </div>
                            <Button
                                data-dismiss="modal"
                                onClick={() => dispatch(showModal())}
                            >
                                <i className="fa-solid fa-xmark m-0"></i>
                            </Button>
                        </Modal.Header>
                        {signupSuccess && (
                            <div>
                                <div className="p-3">
                                    <div className={'row'}>
                                        <h3>Please verify your email</h3>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!signupSuccess && (
                            <div id="signin">
                                <div className="p-3">
                                    {/*<div className="signupType mb-3">*/}
                                    {/*  <div className="row">*/}
                                    {/*    <div className="col-md-4">*/}
                                    {/*      <label className="Selcheckbox ssBox">*/}
                                    {/*        Agency*/}
                                    {/*        <input*/}
                                    {/*          type="radio"*/}
                                    {/*          id="Parent"*/}
                                    {/*          name="isParent"*/}
                                    {/*          defaultChecked={*/}
                                    {/*            userFormState === "parent" ? true : false*/}
                                    {/*          }*/}
                                    {/*        ></input>*/}
                                    {/*        <span className="checkmark"></span>*/}
                                    {/*      </label>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-md-4">*/}
                                    {/*      <label className="Selcheckbox ssBox">*/}
                                    {/*        Renter*/}
                                    {/*        <input type="radio" id="Self" name="isParent"></input>*/}
                                    {/*        <span className="checkmark"></span>*/}
                                    {/*      </label>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-md-4">*/}
                                    {/*      <label className="Selcheckbox ssBox">*/}
                                    {/*        Property Owner*/}
                                    {/*        <input*/}
                                    {/*          type="radio"*/}
                                    {/*          id="Provider"*/}
                                    {/*          name="isParent"*/}
                                    {/*        ></input>*/}
                                    {/*        <span className="checkmark"></span>*/}
                                    {/*      </label>*/}
                                    {/*    </div>*/}
                                    {/*  </div>*/}
                                    {/*</div>*/}
                                    <form
                                        name="usersignup"
                                        className="content"
                                        onSubmit={handleSubmit(_onParentSubmit)}
                                    >
                                        {basicForm}

                                        <div className="d-flex align-items-center justify-content-end">
                                            {loading ? (
                                                <button
                                                    className="black-btn btn-small"
                                                    key={Math.random()}
                                                >
                                                    <span className="RounAnimation"></span> Please Wait...
                                                </button>
                                            ) : (
                                                <button
                                                    key={Math.random()}
                                                    type="submit"
                                                    className="black-btn btn-small"
                                                >
                                                    <i className="fa-solid fa-paper-plane"></i> Sign Up
                                                </button>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default SignUp;
