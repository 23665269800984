import React from "react";
import SecureTopbar from "./SecureTopbar";
import { Heading } from "../profile";
import Home from "../Home";
import * as image from "../../resources/images";

function NewresetPassword() {
  return (
    <div>
      <SecureTopbar />
      <div className="Main">
        <div className="MainContent ">
          <div className="LeftbarPannel m-auto p-0 newsignup">
            <Heading title={"New Registration"} icon={image.changepassword} />

            <div className="PannelContent basicdetailsform px-5">
              <div className="wrapper changepasword">
                <div className="Accountrelated">
                  <form
                    name="changepasswordform"
                    className="bg-white content signupform"
                  >
                    <div className="input-group" key="password">
                      <label htmlFor="newPassword">
                        First Name<span className="mandatoryField">*</span>
                      </label>
                      <div className=" mb15 ">
                        <div className="">
                          <span
                            htmlFor="password"
                            className="form-label PasswordeyeIcon"
                          >
                            <i
                              cla
                              ssName="fa-solid
														 fa-eye"
                            ></i>
                          </span>

                          <input
                            type="text"
                            id="newPassword"
                            placeholder="first name..."
                            className="form-control"
                          />

                          {/* <div className='invalid-feedback'>
												{errors.newPassword?.message}
											</div> */}
                        </div>
                      </div>
                    </div>
                    <div className="input-group" key="password">
                      <label htmlFor="newPassword">
                        Last Name<span className="mandatoryField">*</span>
                      </label>
                      <div className=" mb15 ">
                        <div className="">
                          <span
                            htmlFor="password"
                            className="form-label PasswordeyeIcon"
                          >
                            <i
                              cla
                              ssName="fa-solid
														 fa-eye"
                            ></i>
                          </span>

                          <input
                            type="text"
                            id="newPassword"
                            placeholder="last name..."
                            className="form-control"
                          />

                          {/* <div className='invalid-feedback'>
												{errors.newPassword?.message}
											</div> */}
                        </div>
                      </div>
                    </div>

                    <div className="input-group" key="password">
                      <label htmlFor="newPassword">
                        Country<span className="mandatoryField">*</span>
                      </label>
                      <div className=" mb15 ">
                        <div className="">
                          <span
                            htmlFor="password"
                            className="form-label PasswordeyeIcon"
                          >
                            <i
                              cla
                              ssName="fa-solid
														 fa-eye"
                            ></i>
                          </span>

                          <input
                            type="text"
                            placeholder="Country"
                            className="form-control"
                          />

                          {/* <div className='invalid-feedback'>
												{errors.newPassword?.message}
											</div> */}
                        </div>
                      </div>
                    </div>

                    <div className="input-group" key="password">
                      <label htmlFor="newPassword">
                        Phone <span className="mandatoryField">*</span>
                      </label>
                      <div className=" mb15 ">
                        <div className="">
                          <span
                            htmlFor="password"
                            className="form-label PasswordeyeIcon"
                          >
                            <i
                              cla
                              ssName="fa-solid
														 fa-eye"
                            ></i>
                          </span>

                          <input
                            type="email"
                            id="newPassword"
                            placeholder="Phone no..."
                            className="form-control"
                          />

                          {/* <div className='invalid-feedback'>
												{errors.newPassword?.message}
											</div> */}
                        </div>
                      </div>
                    </div>
                    <div className="input-group" key="password">
                      <label htmlFor="newPassword">
                        Email<span className="mandatoryField">*</span>
                      </label>
                      <div className=" mb15 ">
                        <div className="">
                          <span
                            htmlFor="password"
                            className="form-label PasswordeyeIcon"
                          >
                            <i
                              cla
                              ssName="fa-solid
														 fa-eye"
                            ></i>
                          </span>

                          <input
                            type="email"
                            id="newPassword"
                            placeholder="Email Address..."
                            className="form-control"
                          />

                          {/* <div className='invalid-feedback'>
												{errors.newPassword?.message}
											</div> */}
                        </div>
                      </div>
                    </div>

                    <div className="input-group" key="confirmPassword">
                      <label htmlFor="confirmpassword">
                        Password<span className="mandatoryField">*</span>
                      </label>
                      <div className=" mb15 ">
                        <div className="">
                          <span
                            htmlFor="confirmPassword"
                            className="form-label PasswordeyeIcon"
                          ></span>
                          <input className="form-control" type="Password" />
                        </div>
                      </div>
                    </div>

                    <div className="input-group" key="confirmPassword">
                      <label htmlFor="confirmpassword">
                        Confirm Password
                        <span className="mandatoryField">*</span>
                      </label>
                      <div className=" mb15 ">
                        <div className="">
                          <span
                            htmlFor="confirmPassword"
                            className="form-label PasswordeyeIcon"
                          ></span>
                          <input
                            className="form-control"
                            type="Password"
                            id={"confirmPassword"}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="input-group" key="confirmPassword">
                      <label htmlFor="confirmpassword">
                        <i className="fa-solid fa-info i_style"></i> Passwords
                        must be at least 6 characters.
                        <span className="mandatoryField">*</span>
                      </label>
                    </div>
                    <div className="input-group" key="confirmPassword">
                      <p className="pt-2 pb-2">
                        We will send you a text to verify your phone. Message
                        and Data rates may apply.
                      </p>
                    </div>
                    <div className="input-group full-width basic_details_form changepasswordbutns">
                      <div className="form-group BDsubmitbutton d-flex">
                        <button
                          type="submit"
                          className="btn-blue btn-signup d-block mb-5 changepasswordbtn"
                        >
                          <i className="fa-solid fa-arrow-right-to-bracket mr-1 font-weight-bold"></i>{" "}
                          Sign Up
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default NewresetPassword;
